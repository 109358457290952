.comment-user_details {
	display: inline-block;
	margin-left: 21px;
}

.comment-small {
	font-size: small;
	color: gray;
}

.comment-container {
	position: fixed;
	justify-content: center;
	align-items: center;
	background-color: #ffffffd9;
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	width: 100%;
	height: 100%;
	z-index: 1150;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.comment-modal {
	display: flex;
	flex-direction: column;
	background-color: white;
	box-shadow: 0 0 20px 10px #9b9b9b7a;
	border-radius: 5px;
	width: 550px;
	height: 600px;
	padding: 24px;
}

.comment-header_container {
	display: flex;
	flex-direction: row;
}

.comment-header {
	flex-grow: 1;
}

.comment-close {
	cursor: pointer;
}

.comment-ul {
	flex-grow: 1;
	overflow-y: auto;
	list-style: none !important;
	padding: 15px;
	max-height: 360px;
}

.comment-textarea {
	width: 100%;
	height: 150px;
	border: 1px solid #bdbdbd;
}

.comment-save_and_close {
	margin-top: 15px;
}

.comment-link-button {
	background-color: transparent;
	border: none;
	text-decoration: underline;
	cursor: pointer;
	color: blue;
	margin-right: 12px;
	font-size: small;
}
.comment-link-button.disabled,
.comment-link-button:disabled {
	color: unset;
}
