@import url("./APIAsset/customcss/reset.min.css");

@font-face {
  font-family: "Univers-65Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./APIAsset/fontsStyle/messeunivers-bold.otf") format("opentype");
}

@font-face {
  font-family: "Univers-55Roman";
  font-style: normal;
  font-weight: 400;
  src: url("./APIAsset/fontsStyle/univers-55-roman.otf") format("opentype");
}

@font-face {
  font-family: "Helvetica-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./APIAsset/fontsStyle/helveticabd___.ttf") format("opentype");
}

@font-face {
  font-family: "Optima-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./APIAsset/fontsStyle/optima-b.TTF") format("opentype");
}

@font-face {
  font-family: "HelveticaNeue-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./APIAsset/fontsStyle/helveticaneue.ttf") format("opentype");
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("./APIAsset/googleFonts/S6uyw4BMUTPHjxAwXjeu.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("./APIAsset/googleFonts/S6uyw4BMUTPHjx4wXg.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("./APIAsset/googleFonts/S6u9w4BMUTPHh6UVSwaPGR_p.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("./APIAsset/googleFonts/S6u9w4BMUTPHh6UVSwiPGQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmhduz8A.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwkxduz8A.woff2") format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmxduz8A.woff2") format('woff2');
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlBduz8A.woff2") format('woff2');
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmBduz8A.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmRduz8A.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdu.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --x565a5c: #565a5c;
  --mine-shaft: #333333;
  --hit-gray: #a6adb4;
  --white: #ffffff;
  --black: #000000;
  --science-blue: #0065bd;
  --gallery: #eaecf0;
  --shark-22: #262626;
  --eerie-black: #1b1c1d99;
  --rose: #ff4d4d;
  --lochmara: #0088ce;
  --storm-dust: #656565;
  --black-2: #000000a6;
  --log-cabin: #212121;
  --shark2: #212529;
  --link-water: #cce7f5;
  --pizazz: #ff8a00;
  --black-haze2: #f6f8f9;
  --sonic-silver: #7676764d;
  --concrete: #f3f3f366;
  --gossamer: #009b74;
  --cosmos: #ffdbdb;
  --jade: #00af3f;
  --golden-tainoi: #ffc96c;
  --sazerac: #fff4e1;
  --yellow-orange: #ffb639;
  --piggy-pink: #fee1e6;
  --wild-watermelon: #fa6980;
  --black-haze-22: #f7f7f7;
  --gallery-2: #f0f0f0;
  --dove-gray: #707070;
  --font-size-m: 16px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-xxs: 11px;
  --font-size-l: 18px;
  --font-size-xl: 22px;
  --font-family-univers-55roman: "Univers-55Roman", Helvetica;
  --font-family-universlt_std-65bold: "UniversLT Std-65Bold", Helvetica;
  --font-family-lato: "Lato", Helvetica;
  --font-family-helvetica-bold: "Helvetica-Bold", Helvetica;
  --font-family-univers-65bold: "Univers-65Bold", Helvetica;
  --font-family-univers: "Univers", Helvetica;
  --font-family-univers_lt_std: "Univers LT Std", Helvetica;
  --font-family-universlt_std-55roman: "UniversLT Std-55Roman", Helvetica;
  --font-family-optima-bold: "Optima-Bold", Helvetica;
  --font-family-helveticaneue: "HelveticaNeue", Helvetica;
}

* {
  box-sizing: border-box;
}

h1 {
  color: var(--mine-shaft) !important;
  font-family: var(--font-family-univers-65bold) !important;
  font-size: 46px !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 56px !important;
}

h2 {
  color: var(--mine-shaft) !important;
  font-family: var(--font-family-univers-65bold) !important;
  font-size: 38px !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 48px !important;
}

h3 {
  color: var(--mine-shaft) !important;
  font-family: var(--font-family-univers-65bold) !important;
  font-size: 28px !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 28px !important;
}

h4 {
  color: var(--mine-shaft) !important;
  font-family: var(--font-family-univers-65bold) !important;
  font-size: var(--font-size-xl) !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 32px !important;
}

h5 {
  color: var(--mine-shaft) !important;
  font-family: var(--font-family-univers-65bold) !important;
  font-size: var(--font-size-l) !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 28px !important;
}

/* FONT STYLE */
.helvetica-bold-hit-gray-20px {
  color: var(--hit-gray);
  font-family: var(--font-family-helvetica-bold);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.universltstd-65-bold-black-16px {
  color: var(--black);
  font-family: var(--font-family-universlt_std-65bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.univers-65-bold-black-12px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.univers-65-bold-hit-gray-12px {
  color: var(--hit-gray);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.univers-65-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.univers-65-bold-black-16px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.univers-65-bold-science-blue-16px {
  color: var(--science-blue);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.univers-65-bold-mine-shaft-16px {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.univers-65-bold-mine-shaft-22px {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.univers-55roman-normal-mine-shaft-14px {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.univers-55roman-normal-scarpa-flow-16px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.univers-55roman-normal-mine-shaft-16px {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.univers-55roman-normal-lochmara-16px {
  color: var(--lochmara);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.univers-55roman-normal-hit-gray-12px {
  color: var(--hit-gray);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.univers-55roman-normal-hit-gray-16px {
  color: var(--hit-gray);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.universltstd-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-univers_lt_std);
  font-size: var(--font-size-m);
}

.universltstd-65-bold-black-16px {
  color: var(--black);
  font-family: var(--font-family-universlt_std-65bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.universltstd-55roman-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-universlt_std-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.univers-55roman-normal-scarpa-flow-12px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.univers-55roman-normal-scarpa-flow-14px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.univers-55roman-normal-dove-gray-14px {
  color: var(--dove-gray);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.univers-55roman-normal-scarpa-flow-16px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.univers-55roman-normal-scarpa-flow-18px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.univers-55roman-normal-scarpa-flow-22px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.univers-55roman-normal-mine-shaft-22px {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.univers-65-bold-mine-shaft-38px {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-65bold);
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
}

/* TEXT STYLE */
.small {
  letter-spacing: 0;
  line-height: 22px;
  min-height: 17px;
  text-align: center;

  width: 62px;
}

.large {
  letter-spacing: 0;
  line-height: 26px;
  min-height: 17px;
  text-align: center;
}

.large-long {
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
}

.read-more-text {
  color: var(--lochmara);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-m);
  font-weight: 700;

  letter-spacing: 0;
  line-height: 26px;
  min-height: 17px;
  text-align: center;

  width: 84px;
}

.explore-content {
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;

  width: 52px;
}

.command-add {
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;

  width: 1020px;
}

.text-state-input {
  letter-spacing: 0;
  line-height: 24px;
}

.helper-text-input {
  letter-spacing: 0;
  line-height: 16px;
  width: 370px;
  margin: 4px 0 0 0;
}

.line-height-20px {
  letter-spacing: 0;
  line-height: 20px;
}

.line-height-22px {
  letter-spacing: 0;
  line-height: 22px;
}

.line-height-24px {
  letter-spacing: 0;
  line-height: 24px;
}

.line-height-26px {
  letter-spacing: 0;
  line-height: 26px;
}

.line-height-28px {
  letter-spacing: 0;
  line-height: 28px;
}

.line-height-32px {
  letter-spacing: 0;
  line-height: 32px;
}

.line-height-48px {
  letter-spacing: 0;
  line-height: 48px;
}

.button-small {
  background-image: url("./Components/CommonCSS/images/btn-small.png");
  background-size: 100% 100%;
  height: 35px;
  border-radius: 17.5px;
  min-width: 92px;
  text-transform: none;
}

.button-medium {
  height: 39px;
  border-radius: 20.7px;
  min-width: 100px;
  background-color: #0056a1 !important;
  padding: 11.5px 20px;
  text-transform: none;
}

.button-large {
  background-size: 100% 100%;
  height: 48px;
  border-radius: 24px;
  min-width: 105px;
  background-color: #0056a1 !important;
  padding: 11.5px 29px;
  text-transform: none;
}

.button-large:not(:hover),
.button-medium:not(:hover) {
  background-image: linear-gradient(to right, #0065bd, #0088ce);
}

.button-large-with-arrow-filled {
  background-size: 100% 100%;
  height: 48px;
  border-radius: 24px;
  width: auto;
  text-transform: none;
  padding: 17px 16px 16px 30px;
  background-color: #0056a1 !important;
}

.button-large-with-arrow-filled:not(:hover) {
  background-image: linear-gradient(to right, #0065bd, #0088ce);
}

.css-9tj150-MuiButton-endIcon {
  margin-right: 0;
  margin-left: 15px;
}

.loginTextAlign .MuiButton-startIcon {
  margin-left: 0;
}

.button-large-with-arrow-outlined {
  border: #0065bd 1px solid;
  background-size: 100% 100%;
  height: 48px;
  border-radius: 24px;
  min-width: 169px;
  text-transform: none;
}

.button-cta {
  height: 48px;
  width: 116px;
  text-transform: none;
  white-space: nowrap;
}

.button-back-to-top {
  background-image: url("./Components/CommonCSS/images/back-to-top-container.png");
  background-size: 100% 100%;
  height: 50px;
  min-width: 52px;
  text-transform: none;
  display: flex;
  flex-direction: column;
}

.button-left-swipe {
  background-image: url("./Components/CommonCSS/images/btn-swipe.png");
  background-size: 100% 100%;
  height: 50px;
  border-radius: 25px;
  min-width: 50px;
  color: var(--mine-shaft);
}

.button-right-swipe {
  background-image: url("./Components/CommonCSS/images/btn-swipe.png");
  background-size: 100% 100%;
  height: 50px;
  border-radius: 25px;
  min-width: 50px;
  color: var(--mine-shaft);
}

.css-26w9jf-MuiMobileStepper-dot {
  background-color: var(--science-blue);
  margin: 0 3px;
}

.css-114p2tk-MuiMobileStepper-dot {
  background-color: var(--dove-gray);
  margin: 0 3px;
}

.button-attachment-bar {
  background-image: url("./Components/CommonCSS/images/btn-attachment.png");
  background-size: 100% 100%;
  height: 50px;
  min-width: 1084px;
  text-transform: none;
}

.MuiTextField-root {
  align-items: center;
  height: auto;
}

.MuiInputBase-root {
  align-items: center;
  height: 38px; 
  min-width: 372px;
  border-radius: 5px;
  background-color: var(--white);
}

.MuiOutlinedInput-input {
  letter-spacing: 0;
  line-height: 24px;

  color: var(--mine-shaft);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
  padding: 7px 12px;
}

.MuiFormHelperText-root {
  letter-spacing: 0;
  line-height: 16px;
  width: 370px;
  margin: 4px 0 0 0;
  color: var(--hit-gray);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.css-hdw1oc {
  width: 0;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
  border-radius: 5px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--hit-gray);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option {
  letter-spacing: 0;
  line-height: 16px;

  color: var(--shark2);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option:hover {
  letter-spacing: 0;
  line-height: 16px;

  color: var(--science-blue);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.checkbox-global.Mui-checked {
  color: var(--science-blue);
}

.radio-global.MuiRadio-root.Mui-checked {
  color: var(--science-blue);
}

.header-MPA-OMP {
  width: auto;
  height: 10px;
  background: linear-gradient(89.44deg, #0088ce 0.4%, #009b74 99.54%);
  border-radius: 8px 8px 0 0;
}

.filled-button-MPA-OMP {
  font-family: var(--font-family-univers-65bold);
  padding: 13.5px 48px;
  text-transform: none;
  width: auto;
  height: 51px;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 700;
  font-size: var(--font-size-m);
  line-height: 24px;
  color: var(--white);
  background-color: var(--lochmara);
  text-align: center;
  letter-spacing: 0;
}

.filled-button-MPA-OMP:hover {
  background-color: var(--science-blue);
}

.card-title {
  font-family: var(--font-family-universlt_std-65bold);
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-xl);
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  color: var(--black);
}

.card-title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}

.card-container-global {
  margin: 64px 64px 64px;
}

.login-card {
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center
}

.card-style-global {
  width: auto;
  height: auto;
  background: var(--white);
  box-sizing: border-box;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 75px;
}

.formbox {
  border: 2px solid var(--gallery);
  margin-top: 16px;
  padding: 8px;
}

.commoncss2 .checkbox {
  position: unset;
  width: unset;
  height: unset;
}

.commoncss2 .radio {
  background-color: unset;
  margin-top: unset;
}

.idpgrantform ol {
  list-style: lower-alpha;
}

.commoncss2 .active {
  left: unset;
  top: unset;
  position: unset;
  line-height: unset;
}

.commoncss2 .pagination {
  height: unset;
  width: unset;
  margin-left: unset;
}

.commoncss2 .alert {
  top: unset;
}

#banner-gradient {
  height: 225px;
  width: 270px;
  background: linear-gradient(to right, #0088ce, #009b74);
  border-radius: 8px;
}

#shipbanner {
  position: relative;
  mix-blend-mode: soft-light;
  height: 225px;
  width: 270px;
}

#commoncss2-big-text-box {
  position: relative;
  padding: 36px;
  top: -230px;
  line-height: 32px;
}

#commoncss2-big-text {
  color: #ffffff;
  font-family: var(--font-family-univers-65bold);
  font-size: 28px;
  letter-spacing: 1px;
  font-weight: 700;
  font-style: normal;
}

#commoncss2-guided-journey-button {
  position: relative;
  top: -243px;
  left: 40px;
  align-items: center;
  display: flex;
  height: 50px;
  min-width: 187px;
  padding: 12.7px 18.1px;
  text-transform: none;
  background-color: #ffffff;
  border-radius: 28px;
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}

.typo-text2 {
  letter-spacing: 0;
  line-height: 32px;
}

.scheme-background {
  background-color: #f6f8f9;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.backbanner {
  background-image: url('./Images/Container@2x.png');
  height: 361px;
  width: 100%;
  position: absolute;
}

.backbanner-contactus {
  background-image: url('./Images/Container@2x.png');
  height: 332px;
  width: 100%;
  position: absolute;
}

.aboutomp-backbanner {
  background-image: url('./Images/Container@2x.png');
  height: 355px;
  width: 100%;
  position: absolute;
}

.page-link {
  border: none !important;
  font-family: "Univers-65Bold", Helvetica, sans-serif;
  font-size: 18px;
  padding: 0.375rem 0.75rem !important;
  margin: 8px 8px 8px 0;
  color: #333 !important;
  cursor: pointer;
}

.pagination-carousel .page-link {
  vertical-align: inherit;
  display: inline-block;
}

.page-link:hover {
  background-color: white !important;
}

.infoiconactive {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: transparent;
  margin-right: 2px;
  border: 2px solid #0065bd;
  background-color: #fff;
  background-image: url('./Images/info-circle-blue.svg');
  background-repeat: no-repeat;
  background-position: 6px 6px;
}

.infoiconinactive {
  align-items: center;
  justify-content: center;
  display: flex;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: transparent;
  margin-right: 2px;
  background-color: #0065bd;
  background-image: url('./Images/info-circle.svg');
  background-repeat: no-repeat;
  background-position: 8px 8px;
}
.infoiconactivepartial {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: transparent;
  margin-right: 2px;
  border: 2px solid #0065bd;
  background-image: linear-gradient(to right, white 50%, #f76161 50%);
  background-repeat: no-repeat;
}
.infoiconactiveError {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  color: transparent;
  background-image: url('./Images/info-circle-blue.svg');
  background-repeat: no-repeat;
  background-position: 7px 7px;
}

.tickicon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: transparent;
  margin-right: 2px;
  background-color: #0065bd;
  background-image: url('./Images/check.svg');
  background-repeat: no-repeat;
  background-position: 8px 8px;
}

.partialicon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-right: 3px;
  border-radius: 50%;
  background-image: linear-gradient(to right, white 50%, #f76161 50%);
  border: 2px solid #0065bd;
  color: #0065bd;
  font-family: "Univers-65Bold", Helvetica, sans-serif;
  font-size: 18px;
}

.partialicontext {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0 !important;
}

.partialicontext2 {
  color: #a6a8b4;
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-s);
  left: 11px;
  top: -6px;
  position: relative;
}

.currenticon {
  margin-right: 4px;
  height: 40px;
  width: 40px;
  background-color: #fff;
  color: #0065bd;
  border-radius: 50%;
  border: 2px solid #0065bd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Univers-65Bold", Helvetica, sans-serif;
  font-size: 18px;
}

.emptyicon {
  text-align: center;
  margin-right: 4px;
  height: 40px;
  width: 40px;
  background-color: #eaecf0;
  color: #a6a8b4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Univers-65Bold", Helvetica, sans-serif;
  font-size: 18px;
}

.active .page-link {
  background-color: transparent !important;
  color: #333333 !important;
  top: -4px;
}

.thistable tr:nth-child(1)>:nth-child(n+2):not(:last-child) {
  border-right: 1px transparent solid;
}

.thistable tr:nth-child(1)>:first-child:nth-child(1) {
  border-bottom: 1px transparent solid;
}

.table> :not(:first-child) {
  border-top: 1px solid rgb(222, 226, 230) !important;
}

td {
  border-style: unset !important;
}

table>*>tr {
  border: 1px solid rgb(222, 226, 230) !important;
}

.pagination-carousel .alice-carousel__wrapper {
  width: 88%;
  padding-left: 30px !important;
  top: 40px;
}


@media print {
  .noPrint {
    display: none !important;
  }

  .MuiPaper-root {
    padding: 0;
  }
}

.message-icon {
  content: url('./Images/message.svg');
}

.message-green-icon {
  content: url('./Images/message-green.svg');
}

.formio-component ul {
  list-style: disc;
}

.formio-component ol {
  list-style: lower-alpha;
}

.formio-component ol>li>ol {
  list-style: lower-roman;
}

.formio-component ol[type='1']>* {
  list-style: decimal;
}

.formio-component ol[type='i']>* {
  list-style: lower-roman;
}

.formio-component.numberlist ol>* {
  list-style: decimal;
}

.formio-component.romanlist ol>* {
  list-style: lower-roman;
}

.btn-primary {
  padding-left: 24px !important;
  padding-right: 24px !important;
  height: 48px;
  width: max-content;
}

.btn-primary:not(:hover) {
  background-image: linear-gradient(to right, #0065bd, #0088ce);
}

.btn-info,
.cancelbtn,
.btn-danger {
  padding-left: 24px !important;
  padding-right: 24px !important;
  height: 48px;
  width: max-content;
}

.btn-danger {
  margin-left: 6px;
}

.nested-listing {
  display: flex;
  vertical-align: middle;
  text-align: center;
}

.leftarrowicon {
  background-image: url('./Images/arrow-narrow-left.svg');
  width: 22px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.leftarrowiconblue {
  background-image: url('./Images/arrow-narrow-left-blue.svg');
  width: 22px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.rightarrowicon {
  background-image: url('./Images/arrow-narrow-right.svg');
  width: 22px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}

.saveasdraftbtn:not(:hover),
.cancelbtn:not(:hover) {
  background-image: unset !important;
  background-color: white !important;
  color: #0065bd !important;
  border-color: #0065bd !important;
}

.backbtn:not(:hover) {
  background-image: unset;
  background-color: white !important;
  color: #0065bd !important;
}

.backbtn:hover,
.saveasdraftbtn:hover,
.cancelbtn:hover {
  background-image: unset;
  background-color: rgba(0, 101, 189, 0.04) !important;
  color: #0065bd !important;
}


/* GENERAL MARGIN SPACING */
.mb-xs {
  margin-bottom: 10px;
}

.mb-s {
  margin-bottom: 30px;
}

.mb-m {
  margin-bottom: 60px;
}

.mb-l {
  margin-bottom: 90px;
}

.mb-xl {
  margin-bottom: 120px;
}

.formio-button-add-row {
  width: 100%;
  border-radius: 4px !important;
  background-image: unset !important;
  background-color: #0088CE1A !important;
  border: 1px dashed #A6ADB4 !important;
  color: #565A5C !important;
}

.formio-button-remove-row {
  background-color: unset !important;
  border: unset !important;
  background-image: url('./Images/icon-tabler-delete.svg');
  background-repeat: no-repeat;
  min-height: 24px;
}

.formio-button-remove-row>.fa-times-circle-o {
  display: none;
}

.fileSelector {
  background-color: #0088CE1A;
  border-radius: 4px;
  border: 1px dashed #A6ADB4 !important;
}

.form-control[disabled='disabled'] {
  background-color: #e9ecef;
}

.choices.is-disabled,
.choices.is-disabled .choices__item {
  cursor: unset !important;
}

.form-option-button {
  background-color: transparent;
  color: #666666;
  box-shadow: none;
  border: 2px solid #A6ADB4;
  height: 40px;
  width: 40px;
}

.formio-errors .error {
  color: #dc3545;
  overflow: hidden;
  white-space: normal;
}

.footer-link,
.footer-link:hover {
  text-decoration: none;
  color: white;
}

.MuiDataGrid-iconSeparator {
  display: none;
}

.MuiDataGrid-cellCheckbox>span>svg,
.MuiDataGrid-columnHeaderTitleContainerContent>span>svg {
  color: #aaa;
  font-size: 1.334rem;
}

/* MSI-AIS Main Page 6 */
.collapsible-fixed>table {
  table-layout: fixed;
  width: 100%;
  overflow-x: hidden;
}

.collapsible-fixed>table label,
.collapsible-fixed>table p {
  white-space: normal;
}

.collapsible-fixed>table>thead>tr>th:first-of-type {
  width: 95%;
}

.collapsible-fixed>table>thead,
.collapsible-fixed>table th {
  visibility: collapse;
}

.collapsible-fixed ul[role="tablist"] {
  list-style: none;
}

.fixed-table td:not(:first-child) {
  width: 28.2ch;
}

.projections-table-row button {
  background-color: red;
}

div.hideDetails .details table tr:not(:first-child) {
  display: none;
}

div .details table tr:not(:first-child) {
  background-color: #F6F8F9
}

td button.expand-icon.btn-primary,
td button.collapse-icon.btn-primary,
td button.expand-icon.btn-primary:not(:hover),
td button.collapse-icon.btn-primary:not(:hover) {
  border-radius: 0;
  border-color: transparent;
  height: auto;
  background-image: none;
  background-color: transparent !important;
  padding: 0 !important;
  width: 100%;

}

td button.expand-icon.btn-primary span,
td button.collapse-icon.btn-primary span,
td button.expand-icon.btn-primary:not(:hover) span,
td button.collapse-icon.btn-primary:not(:hover) span {
  color: black;
  font-size: 1.6em;
}

.no-spacing p {
  margin-bottom: 0;
  padding-top: 6px;
  padding-bottom: 6px;
}

.disabled-link {
  color: #A6ADB4 !important;
  vertical-align: middle;
  cursor: default;
}

.active-link {
  vertical-align: middle;
  cursor: pointer;
}

.nav-back-btn {
  padding: 0;
  min-width: unset;
  margin-right: 18px;
}

.back-to-top {
  position: fixed;
  bottom: 10%;
  right: 40px;
  height: 50px;
  width: 50px;
  min-width: unset;
  background: #ffffff;
  border: 2px solid #EAECF0;
  color: #333333;
  cursor: pointer;
  border-radius: 4px;
}

.back-to-top:hover {
  background-color: #f5f9fc;
}

.service-provider-table table tr>td:first-child {
  width: 30%;
}

.service-provider-table table.datagrid-table tr>td:first-child {
  width: auto;
}

.dataTables_info {
  display: inline-flex;
  width: 50%;
  vertical-align: top;
  margin-top: 20px;
}

.dataTables_paginate.paging_full_numbers {
  display: inline-flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
}

.dataTables_paginate>.paginate_button,
.dataTables_paginate>span>.paginate_button {
  margin: 4px;
  text-decoration: none;
  cursor: pointer;
  padding: 5px;
}

.dataTables_paginate>span>.paginate_button {
  padding: 6.79px 11.55px;
}

.dataTables_paginate>span>.paginate_button.current {
  background-color: var(--science-blue);
  color: white;
  border-radius: 16px;
}

.dataTables_length {
  margin-top: 4px;
  margin-bottom: 8px;
}

.dataTables_paginate.paging_full_numbers>.paginate_button.first {
  content: url('./Images/keyboard-double-arrow-left.svg');
}

.dataTables_paginate.paging_full_numbers>.paginate_button.previous {
  content: url('./Images/keyboard-arrow-left.svg');
}

.dataTables_paginate.paging_full_numbers>.paginate_button.next {
  content: url('./Images/keyboard-arrow-right.svg');
}

.dataTables_paginate.paging_full_numbers>.paginate_button.last {
  content: url('./Images/keyboard-double-arrow-right.svg');
}

.dataTables_paginate.paging_full_numbers>.disabled {
  opacity: 0.4;
}

table.dataTable>thead>tr>th.sorting_asc:after {
  content: url('./Images/filter-up.svg');
}

table.dataTable>thead>tr>th.sorting_desc:after {
  content: url('./Images/filter-down.svg');
}

.borderless-insidecard .card {
  border: none;
}

.btnAlignEnd {
  display: flex;
  justify-content: flex-end;
}

.custom-columns .col-md-5 {
  padding: unset;

}

.custom-columns .col-md-3 {
  padding: unset;
}

.certNum-columns .col-md-6 {
  padding-right: unset;
}

.certNum-columns .col-md-4 {
  padding: unset;
}

.square-button .btn.btn-primary {
  margin-right: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  height: 38px !important;
  background-color: #e9ecef !important;
  /*will change back*/
  width: 50px !important;
  padding-left: 7px !important;
  padding-right: 0 !important;
}

.square-button:not(:hover) {
  border-radius: 0 !important;
  border: none !important;
  height: 38px !important;
  background-image: linear-gradient(to right, white #e9ecef);
  width: 50px !important;
}

.square1-button .btn.btn-primary {
  margin-top: 38px;
  margin-right: 0 !important;
  border-radius: 0 !important;
  border: none !important;
  height: 38px !important;
  background-color: #e9ecef !important;
  /*will change back*/
  width: 50px !important;
  padding-left: 7px !important;
  padding-right: 0 !important;
}

.square1-button:not(:hover) {
  border-radius: 0 !important;
  border: none !important;
  height: 38px !important;
  background-image: linear-gradient(to right, white #e9ecef);
  width: 50px !important;
}

.fa-search:before {
  color: darkgray;
}

.custom-label .form-check .form-check-input {
  float: none !important;
}

.formio-component-cessionTable tr>:nth-child(3) {
  padding-right: 20px;
  padding-left: 20px;
}

.formio-component-cessionTable tr>:nth-child(4) {
  padding-left: 20px;
}

.formio-component-srsFinanceTable td.cell-align-left {
  min-width: 200px;
}

.formio-component-cessionContainerTable tr>:nth-child(2) {
  padding-right: 30px;
  padding-left: 20px;
}

.dropdown .form-control.is-invalid {
  background-position: right calc(1.5em + 0.1875rem) center
}

.form-check-input:disabled {
  background-color: #e9ecef;
}


.background-grey {
  background-color: #d9dcdf;
  margin-left: 0 !important;
}

.nested-config {
  display: none;
}

.buttonMargin {
  margin: 10px;
  float: right;
}

.optional label::before {
  content: "[optional]";
  font-style: italic;
}

.contactus-optional p::after {
  content: "[optional].";
  font-style: italic;
  font-size: inherit !important;
}

.field-required::after,
.field-required::before {
  display: none;
}

.formio-component-selectboxes.required label.form-check-label::before {
  display: none;
}

.inject-tooltip:hover:after {
  display: inline;
  margin-top: 13px;
}

.inject-tooltip:after {
  content: attr(tooltip-text);
  position: absolute;
  transform: translateY(-50%);
  padding: 8px;
  border-radius: 8px;
  background: #555;
  color: #fff;
  text-align: center;
  display: none;
}

table .sr-only {
  position: relative;
  display: none;
}

.expenses-table table,
.vessel-listing table {
  width: 100%
}

/* Vessel listing table */
.vessel-listing table thead>tr>th:nth-of-type(3) {
  width: 13%
}

.vessel-listing table thead>tr>th:nth-of-type(4) {
  width: 18%
}

.vessel-listing table thead>tr>th:nth-of-type(5) {
  width: 18%
}

.vessel-listing table thead>tr>th:nth-of-type(6) {
  width: 12%
}

.vessel-listing table thead>tr>th:nth-of-type(7) {
  width: 5%
}

.expenses-table table thead>tr>th:first-of-type,
.expenses-table table tbody>tr>td:first-of-type {
  width: 25%;
}

.expenses-table table thead>tr>th:nth-of-type(8),
.expenses-table:not(.table-review) table tbody>tr>td:nth-of-type(8) {
  width: 5%;
}

.table-review table tbody>tr>td:last-of-type {
  padding: 0;
}

.projections-headcount-row table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.projections-headcount-row:not(.head-foot) table tbody>tr>td:first-of-type {
  width: 5% !important;
}

.table-review .projections-headcount-row:not(.head-foot) table tbody>tr>td:first-of-type {
  width: 0 !important;
}

.projections-headcount-row.head-foot table tbody>tr>td:first-of-type,
.table-review .projections-headcount-row:not(.head-foot) table tbody>tr>td:nth-of-type(2) {
  width: 25% !important
}

.projections-headcount-row:not(.head-foot) table tbody>tr>td:nth-of-type(2) {
  width: 20% !important;
}

.projections-headcount-row table tbody>tr>td:nth-last-of-type(1) {
  width: 20% !important;
}

.page-blank {
  display: none;
  background-color: red;
}

.clickContactText {
  color: #0065bd;
}

.clickContactText:hover {
  text-decoration: underline;
}

.advisoryColor {
  background-color: var(--x565a5c);
  color: white;
}

.alignContent {
  margin-left: 10%;
  margin-right: 10%;
}

.custom-class {
  background-color: #84c9d2;
  border-color: #0065bd !important;
  color: #ffffff;
}

.custom-vessel-upload-template {
  background-color: white !important; 
  border-style: none !important; 
  color: black !important;
  margin: 0 !important;
  padding: 0 !important;
  font-family: var(--font-family-univers-55roman) !important;
  pointer-events: none;
}

.btn_cancel {
  height: 39px;
  border-radius: 20.7px !important;
  border: 1px solid #0056a1 !important;
  min-width: 100px;
  border-style:solid !important;
  color: #0056a1 !important;
  background-color: white !important;
  padding: 11.5px 20px;
  text-transform: none;
}
