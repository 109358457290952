
/* do not delete below .help-block or there will be jira issue MOSP2BT-1476 */
.help-block {
    display: none !important;
}

.contactus .col-md-10 {
    width: 87%;
    margin-left: -25px;
}

.contactus .col-md-2 {
    width: 15%;
}

.contactus [ref='component']:not(.formio-component-columns ) {
    margin-top: 20px;
}

.contactus [ref='component'] > p[ref='html'] {
    margin-bottom: 0;
}

.contactus .radio {
    padding-top: 0;
}

.contactus .formio-component-columns > * > div {
    margin-top: 10px !important;
}

.contactus .formio-component-button {
    text-align: right;
}

.contactUsImg {
    object-fit: cover;
}

#grpdiscusspic {
    height: 390px;
    mix-blend-mode: multiply;
    position: absolute;
    top: 146px;
    right: 10%;
    width: 560px;
  }

#contactuspic {
    height: 330px; /* Let the image adjust its height proportionally */
    mix-blend-mode: multiply;
    opacity: 1;
    width: 900px !important;
    margin-top: -120px;
  }
  .contactform {
    padding: 0px 0px !important;
    margin-top: -15px;
    }
 
  @media (max-width: 600px) {
   .contactform {
    padding: 16px 4px !important;
    margin-top: 0px;
    }
  }

  @media only screen and (min-width: 1920px) {
    .exploreschemes {width: 100%;}
    
    #contactuspic {
        height: 330px; /* Let the image adjust its height proportionally */
        mix-blend-mode: multiply;
        opacity: 1;
        width: 900px !important;
        margin-top: -120px;
        margin-left: 90px;
    }
  }