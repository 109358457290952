.journey-container {
  background-image: url("../../Images/holder-Bg-guidejourney.png");
  background-size: 100% 100%;
  display: flex;
  min-width: 1181px;

  top: 669px;
}

.containerGuidedJourney {
  display: flex;
  justify-content: center;
  align-content: center;

}

.scheme-container {
  background-image: url("../../Images/holder-Bg-guidejourney.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  min-width: 1181px;
  top: 669px;
  z-index: 1;
}

.scheme-box {
  padding: 56px 63px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.big-text {
  color: #ffffff;
  font-size: 46px;
  font-weight: 700;
  font-style: normal;
}

.big-text-box {
  letter-spacing: 0;
  line-height: 48px;
  min-height: 92px;
  width: 578px;
}

.guided-journey-button {
  align-items: center;
  display: flex;
  height: 50px;
  min-width: 187px;
  padding: 13px 18px;
  text-transform: none;
  background-color: #ffffff;
  border-radius: 25px;

  font-size: 16px;
  font-weight: 700;
  color: #333333;
}

.guided-journey-button:hover {
  background-color: #f5f9fc;
}

.span0 {
  font-weight: 400;
}

.big-div {
  font-weight: 700;
  letter-spacing: 0;
  line-height: 48px;
  width: 584px;
  margin-right: 55px;
  font-size: 38px;
}

.indicator-1 {
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: -9px;
  margin-top: -6px;
  min-height: 17px;
  white-space: nowrap;
  font-family: var(--font-family-univers-55roman);
}

.small-blue-text {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #0065bd;
}

.subtitle {
  height: 81px;
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 53px;
  width: 529px;
  font-family: var(--font-family-univers-55roman);
}

.scheme-card {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px #00000029;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 10px;
  min-height: 478px;
  padding: 15px 15px;
  width: 365px;
  max-width: 365px;
}

.image-1 {
  background-image: url("../../Images/Image 1@2x.png");
  background-size: 100% 100%;
  display: flex;
  height: 245px;
  left: 0;
  top: 0;
  width: auto;
}

.image-2 {
  background-image: url("../../Images/image 2@2x.png");
  background-size: 100% 100%;
  display: flex;
  height: 245px;
  left: 0;
  top: 0;
  width: auto;
}

.image-3 {
  background-image: url("../../Images/img3.png");
  background-size: 100% 100%;
  display: flex;
  height: 245px;
  left: 0;
  top: 0;
  width: auto;
}

.image-afsa {
  background-image: url("../../Images/image_afsa_small.png");
  background-size: 100% 100%;
  display: flex;
  height: 245px;
  left: 0;
  top: 0;
  width: auto;
}

.image-container {
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(143, 143, 143, 0) 43.84%,
      #000000 100%);
  border-radius: 4px;
  box-shadow: 0 3px 6px #00000029;
  flex: 1;
  width: 343px;
}

.image-text {
  height: 28px;
  left: 15px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 188px;
  white-space: nowrap;
  width: 315px;
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
}

.image-text-wht {
  height: 63px;
  left: 24px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 157px;
  width: 315px;
  font-weight: 700;
  font-size: 22px;
  color: #ffffff;
}

.overlay-group {
  height: 241px;
  position: relative;
  width: 343px;
  align-self: center;
}

.scheme-description {
  height: 126px;
  letter-spacing: 0;
  line-height: 26px;
  margin-right: 14px;
  padding-left: 15px;
  margin-top: 23px;
  width: 315px;
  color: rgba(86, 90, 92, 1);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  align-self: center;
}

.chip {
  display: flex;
  height: 25px !important;
  justify-content: flex-end;
  padding: 2.5px 25px;
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  min-width: 40px;
  text-align: center;
  white-space: nowrap;
  border: #a6adb4 solid 1px;
  background-color: #ffffff;

  font-size: 12px;
  font-weight: 700;
  font-style: normal;
}

.indicator-2 {
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: -9px;
  margin-right: 24px;
  margin-top: -6px;
  min-height: 17px;
  min-width: 270px;
  text-align: center;
  white-space: nowrap;

  font-style: normal;
  font-weight: 400;
  color: #0065bd;
  font-size: 16px;
}

.small-header {
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: -12px;
  margin-right: 24px;
  margin-top: 14px;
  min-height: 36px;
  text-align: center;
  white-space: nowrap;

  font-weight: 700;
  font-style: normal;
  font-size: 28px;
}

.subheader-desc {
  height: 49px;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 17px;
  text-align: center;

  font-size: 18px;
  font-weight: 400;
  font-style: normal;
}

.calendar-background {
  background-color: #ffffff;
  border-radius: 4px;
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 60px;

  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 101, 189, 1);
}

.calendar-header {
  left: 0;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  width: 60px;

  background-color: rgba(0, 101, 189, 1);
  border-radius: 4px 4px 0 0;

  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
}

.calendar-body {
  left: 0;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 32px;
  white-space: nowrap;
  width: 62px;

  font-weight: 700;
  font-style: normal;
  color: #0065bd;
  font-size: 22px;
}

.notice-overlap {
  height: 60px;
  position: relative;
  width: 62px;
}

.notice-bold {
  height: 93px;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: -5px;
  width: 275px;

  font-weight: 700;
  font-style: normal;
  font-size: 22px;
}

.notice-subtitle {
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 1px;
  white-space: nowrap;

  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #565a5c;
}

.seemore-button {
  align-items: center;
  display: flex;
  color: #ffffff;
  height: 50px;
  min-width: 187px;
  padding: 12.7px 18.1px;
  text-transform: none;
  background-color: #0088ce !important;
  border-radius: 25px;

  font-size: 16px;
  font-weight: 700;
}

.seemore-button:not(:hover) {
  background-image: linear-gradient(to right, #0088ce, #009b74);

}

.explore-button {
  align-items: center;
  display: flex;
  color: #ffffff;
  height: 50px;
  min-width: 187px;
  padding: 12.7px 24px;
  text-transform: none;
  border-radius: 25px;
  background-color: #0056a1 !important;

  font-size: 16px;
  font-weight: 700;
}

.explore-button:not(:hover) {
  background-image: linear-gradient(to right, #0065bd, #0088ce);
}

.about-omp-button {
  align-items: center;
  display: flex;
  height: 50px;
  min-width: 160px;
  padding: 12.7px 24px;
  text-transform: none;
  background-color: #ffffff;
  border-radius: 25px;

  font-size: 16px;
  font-weight: 700;
  color: #333333;
}

.about-omp-button:hover {
  background-color: #f5f9fc !important;
}

.carousel-buttons-container {
  align-items: flex-start;
  display: flex;
  top: 540px !important;
  position: absolute;
  min-width: 109px;
  z-index: 2;
}

.btn-container {
  display: flex;
  justify-content: center;
  margin-top: 63px;
}

#scheme-background {
  background-color: #eaecf0;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.main-box {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 83px;
  min-height: 273px;
}

.title-indicator {
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: -4px;
  margin-top: -3px;
  min-height: 18px;
  white-space: nowrap;

  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}

.title-box {
  align-items: flex-end;
  display: flex;
  height: 154px;
  margin-top: 31px;
  width: 574px;
}

.h1-title {
  flex: 1;
  height: 156px;
  letter-spacing: 0;
  line-height: 56px;
  margin-right: -2px;

  font-size: 46px;
  font-weight: 700;
  color: #ffffff;
}

.subheader-box {
  align-items: flex-end;
  display: flex;
  height: 51px;
  margin-top: 22px;
  width: 550px;
  color: #ffffff;
}

.subheader {
  flex: 1;
  height: 53px;
  letter-spacing: 0;
  line-height: 28px;
  margin-right: -2px;

  font-size: 16px;
  font-weight: 400;
}

.carousel-background {
  align-items: flex-start;
  background-size: 100% 100%;
  min-height: 768px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 400px) {
  .carousel-background {
    display: flex;
    flex-direction: column;
    height: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .about-omp-button {
    align-items: center;
    display: flex;
    height: 50px;
    width: 200px;
    padding: 12.7px 24px;
    text-transform: none;
    background-color: #ffffff;
    border-radius: 25px;

    font-size: 16px;
    font-weight: 700;
    color: #333333;
  }

  .explore-button {
    align-items: center;
    display: flex;
    color: #ffffff;
    height: 50px;
    width: 220px;
    padding: 12.7px 24px;
    text-transform: none;
    border-radius: 25px;
    background-color: #0056a1 !important;

    font-size: 16px;
    font-weight: 700;
  }
}

#background-1 {
  background-image: url("../../Images/Slider-Img1-BG1 2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#background-2 {
  background-image: url("../../Images/Slider-Img1-BG1@edit.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.overlay-carousel {
  height: 876px;
  position: relative;
  width: 1440px;
}

.overlay-group-container {
  margin-top: -95px;
  height: auto;
  position: relative;
  width: auto;
}

#homepage {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #0065be !important;
}

.alice-carousel__dots-item:not(.__custom) {
  background-color: #707070 !important;
}

.new-carousel-prev-btn,
.new-carousel-next-btn {
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 50%;
  border: 1px solid white;
  margin: 5px;
  position: relative;
  top: -130px;
  left: 15px;
  background-repeat: no-repeat;
  background-position: 12px 12px;
}

.overlap-carousel .alice-carousel {
  margin-bottom: -60px !important;
}

.new-carousel-prev-btn {
  background-image: url("../../Images/chevron-left-white.svg");
}

.new-carousel-next-btn {
  background-image: url("../../Images/chevron-right-white.svg");
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: none !important;
}

.schemecontainer {
  padding-top: 120pt;
  padding-bottom: 54pt;
}

@media (max-width: 620px) {
  .schemecontainer {
    padding-top: 160pt;
  }
}

@media (max-width: 574px) {

  .title-box,
  .subheader-box {
    width: 100%;
  }

  .h1-title {
    font-size: 2em;
  }
}

@media (max-width: 1155px) {
  .scheme-container {
    min-width: unset;
  }

  .guided-journey-button {
    white-space: nowrap;
  }

  .big-text-box {
    width: unset;
  }
}

@media (max-width: 768px) {
  .scheme-box {
    flex-direction: column;
    padding: 2rem 2rem 3rem;
  }

  .big-text {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .big-div {
    margin-bottom: 16px;
    margin-right: unset;
    white-space: break-spaces;
    font-size: 2rem;
    width: unset;
    height: unset;
  }

  .subtitle {
    width: unset;
    margin-left: unset;
    height: unset;
  }

  .footer-padding {
    padding: 53.5px 32px;
  }

  .description {
    width: unset !important;
    height: unset !important;
    font-size: 1rem !important;
  }

  .one-maritime-portal {
    font-size: 1.5rem !important;
  }

  .power-by,
  .contact-us,
  .report-vulnerability,
  .privacy-policy,
  .terms-of-use,
  .x2020-maritime-and,
  .copyright {
    font-size: 0.9rem !important;
  }

  .flex-row-3 {
    flex-direction: column;
    align-items: unset !important;
  }

  .copyright,
  .x2020-maritime-and {
    white-space: unset !important;
  }

  .powered-by {
    margin-bottom: 12px;
  }

  .scheme-card {
    width: 358px;
    max-width: 100%;
  }

}

@media (min-width: 768px) {
  .footer-padding {
    padding: 53.5px 134px;
  }
}

@media (max-width: 380px) {
  .scheme-card {
    max-width: 100%;
  }

  .subtabs {
  }

  .overlay-group {
    width: 310px;
    max-width: 313px;
  }

  .schemecontainer {
    padding-top: 180pt;
  }
}

.clickText {
  color: white;
  cursor: pointer;
}

.clickText:hover {
  color: #00ffff;
}

.clickLongText {
  color: black;
}

.clickLongText:hover {
  color: blue;
}
