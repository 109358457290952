
.testFont {
    font-family: "Univers-65Bold", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: xx-large;
}

.testFont2 {
    font-family: "Univers-55Roman", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.loginBtn {
    font-family: "Univers-55Roman", sans-serif;
    font-weight: 700;
    text-transform: capitalize;
    align-items: flex-start;
    background: linear-gradient(90deg, #0065bd 52.88%, #0088ce 100%);
    border-radius: 24px;
    display: flex;
    height: 48px;
    justify-content: flex-end;
    padding: 11.5px 153px;
    margin-top: 7px;
    margin-bottom: 20px;
}

.tempTextfield.MuiTextField-root {
    width: 356px;
}
