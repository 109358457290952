.appbadge {
  margin: 1000px 100px 120px 10px;
}

.line-18 {
  height: 8px;
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 8px;
  width: 2px;
}

.path-10451 {
  height: 20px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 16px;
}

.path-10452 {
  height: 5px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 11px;
  width: 9px;
}

.path-10715 {
  height: 17px;
  object-fit: cover;
  width: 20px;
}

.msi-ais {
  -webkit-text-stroke: 1px transparent;
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  white-space: nowrap;
}

.created-on-21-oct-2021 {
  -webkit-text-stroke: 1px transparent;
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  white-space: nowrap;
}

.appsbutton-small {

  max-width: 1500px;

}

.all {
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  width: 62px;

}

.processing {
  height: 17px;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 16px;
  margin-top: -4.7px;
  text-align: center;
  white-space: nowrap;
  width: 87px;
}

.update-request {
  height: 17px;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 16px;
  margin-top: -4.7px;
  text-align: center;
  white-space: nowrap;
  width: 127px;
}

.completed {
  height: 17px;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 16px;
  margin-top: -4.7px;
  text-align: center;
  white-space: nowrap;
  width: 90px;
}

.surname {
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  white-space: nowrap;
}

.tag {
  align-items: flex-start;
  background-color: var(--sazerac);
  border-radius: 12px;
  display: flex;
  height: 24px;
  min-width: 40px;
  padding: 0.5px 4.4px;
}

.border-1px-golden-tainoi {
  border-width: 1px;
  border-style: solid;
  border-color: var(--golden-tainoi);
}

.univers-65-bold-yellow-orange-12px {
  color: var(--yellow-orange);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-xs);
  font-weight: 700;
  font-style: normal;
}

.buttons-small {
  background-color: #FFF4E1;
  background-size: 100% 100%;
  height: 35px;
  border-radius: 17.5px;
  min-width: 30px;
  text-transform: none;
}

.white-pin {
  background-color: #0088CE;
  height: 20px;
  width: 20px;
  border-radius: 5px 0 5px 0;
  background-image: url('../../Images/pin.svg');
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-position: 3px 3px;
}

.white-pin-list {
  background-color: #0088CE;
  height: 15px;
  width: 15px;
  background-image: url('../../Images/pin.svg');
  background-size: 11px 11px;
  background-repeat: no-repeat;
  background-position: 2px 2px;
  position: relative;
  top: -58px;
  left: -60px;
  border-radius: 0 0 5px 0;
}

.red-circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #FF4D4D;
  border: 1px solid white;
  display: inline-block;
  margin-left: 3px;
}

.allow-overflow {
  overflow: visible !important;
}

.MuiDataGrid-row .white-background {
  background-color: white;
}

.MuiDataGrid-row:hover .white-background {
  background-color: #f5f5f5;
}

.MuiDataGrid-row.Mui-selected .white-background {
  background-color: #ebf3fa;
}

.MuiDataGrid-row.Mui-selected:hover .white-background {
  background-color: #e0ecf7;
}

.filter-dialog .MuiInputBase-root {
  min-width: unset;
}

.scheme-background {
  background-color: #f6f8f9;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}