.corp-pass {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .sing-pass {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .indlogin-button {
    font-family: Open Sans, sans-serif !important;
    padding: 13.5px 48px;
    text-transform: none !important;
    width: 300px;
    height: 51px !important;
    box-sizing: border-box;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 150% !important;
    color: #ffff !important;
    text-align: center !important;
    letter-spacing: 0.2px !important;
    border-radius: 30px;
    background-color: #d0343a !important;
  }

  .indlogin-button-disabled {
    font-family: Open Sans, sans-serif !important;
    padding: 13.5px 48px;
    text-transform: none !important;
    width: 300px;
    height: 51px !important;
    box-sizing: border-box;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 150% !important;
    color: #ffff !important;
    text-align: center !important;
    letter-spacing: 0.2px !important;
    border-radius: 30px;
    background-color: lightgray !important;
  }

  .orglogin-button {
    font-family: Open Sans, sans-serif !important;
    padding: 13.5px 48px;
    text-transform: none !important;
    width: 300px;
    height: 51px !important;
    box-sizing: border-box;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 150% !important;
    color: #ffff !important;
    text-align: center !important;
    letter-spacing: 0.2px !important;
    border-radius: 30px;
    background-color: #0065ce !important;
  }
  .bluetext {
    color: #0088ce;
    font-weight: 600;
    margin-left: 6px;
    margin-right: 6px;
    text-transform: none;
  }
  .prelogin-overlay {
    background-color: var(--mine-shaft);
    opacity: 0.5;
    width: 100%;
    height: 400px;
    border-radius: 0 !important;
  }
