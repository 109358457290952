.css-o4b71y-MuiAccordionSummary-content {
  display: flex !important;
  justify-content: space-between !important;
  align-content: center;
}

.css-1ynyhby {
  padding-right: 12px;
}

.grid-container-margin {
  margin: 0 64px 64px;
}

.accordion-padding-global {
  padding: 32px 24px 64px 24px;
}

.MuiAccordionSummary-root {
  background-color: #f7f7f8;
  font-family: Open Sans, sans-serif;
  color: #006cea;
  font-weight: 600;
}

.accordion-global.MuiAccordionSummary-content {
  display: flex !important;
  justify-content: space-between !important;
  align-content: center;
}

.success-accordion.MuiBox-root {
  width: auto;
  display: flex;
  align-items: center;
}

.success-accordion {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: right;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  color: #128667;
  display: flex;
  align-content: center;
  margin-top: 5px;
}

.error-accordion.MuiBox-root {
  width: auto;
  display: flex;
  align-items: center;
}

.error-accordion {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: right;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  color: #ba0512;
  display: flex;
  align-content: center;
  margin-top: 5px;
}

.accordion-padding {
  padding: 32px 24px 64px 24px;
}

.filled-button {
  font-family: Open Sans, sans-serif !important;
  padding: 14px 48px;
  text-transform: none !important;
  width: auto !important;
  height: 51px !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 150% !important;
  color: #ffff !important;
  background-color: #d0343a !important;
  text-align: center !important;
  letter-spacing: 0.2px !important;
}

.button-split {
  width: auto !important;
  height: 51px !important;
}

.filled-split {
  font-family: Open Sans, sans-serif !important;
  text-transform: none !important;
  background-color: #d0343a !important;
  color: #ffffff;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 150% !important;
  padding: 0 32px 0 32px;
}

.filled-split.Mui-disabled {
  font-family: Open Sans, sans-serif !important;
  text-transform: none !important;
  background-color: #c2c2c2 !important;
  color: #ffffff;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 150% !important;
  padding: 0 32px 0 32px;
}

.filled-split:hover {
  background-color: #a02a2e !important;
}

.button-split.MuiButtonBase-root.MuiButton-root {
  border: none;
}

.filled-dropdown.MuiButtonBase-root.MuiButton-root {
  border-left: #ffffff 2px solid;
}

.filled-dropdown {
  height: auto !important;
  background-color: #d0343a !important;
  color: #ffffff;
  width: 22px !important;
  padding: 0 13px 0 13px;
}

.filled-dropdown.Mui-disabled {
  height: auto !important;
  background-color: #c2c2c2 !important;
  color: #ffffff;
  width: 22px !important;
  padding: 0 13px 0 13px;
}

.filled-dropdown:hover {
  background-color: #a02a2e !important;
}

.outlined-split {
  text-transform: none !important;
  font-family: Open Sans, sans-serif !important;
  color: #d0343a;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 150% !important;
  padding: 0 32px 0 32px;
  border-radius: 4px;
}

.outlined-split.Mui-disabled {
  text-transform: none !important;
  font-family: Open Sans, sans-serif !important;
  background-color: #f6f5f5;
  color: #878d8d;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 150% !important;
  padding: 0 32px 0 32px;
}

.outlined-split:hover {
  background-color: #EAECF0;
}

.outlined-dropdown {
  height: auto !important;
  color: #0065BD;
  width: 22px !important;
  padding: 0 13px 0 13px;
  border-radius: 4px;
}

.outlined-dropdown.Mui-disabled {
  height: auto !important;
  background-color: #f6f5f5;
  color: #878d8d;
  width: 22px !important;
  padding: 0 13px 0 13px;
}

.outlined-dropdown:hover {
  background-color: #EAECF0;
}

.outlined-split.MuiButtonBase-root.MuiButton-root {
  border-top: #0065BD 2px solid;
  border-left: #0065BD 2px solid;
  border-bottom: #0065BD 2px solid;
}

.outlined-split.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  border-top: #c2c2c2 2px solid;
  border-left: #c2c2c2 2px solid;
  border-bottom: #c2c2c2 2px solid;
}

.outlined-dropdown.MuiButtonBase-root.MuiButton-root {
  border: #0065BD 2px solid;
}

.outlined-dropdown.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  border: #c2c2c2 2px solid;
}

.icon-button {
  display: flex !important;
  width: 51px !important;
  height: 51px !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 12px !important;
  color: #d0343a;
  background: #ffffff !important;
  border: 2px solid #d0343a !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.icon-button:focus {
  display: flex !important;
  width: 51px !important;
  height: 51px !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 12px !important;
  color: #ffffff !important;
  background: #a02a2e !important;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
}

.success-circular-button {
  display: flex !important;
  width: 51px !important;
  height: 51px !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 12px !important;
  box-sizing: border-box !important;
  border-radius: 100% !important;
  background: #ffffff !important;
  border: 2px solid #128667 !important;
  color: #128667 !important;
  box-shadow: none !important;
}

.success-circular-button:focus {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 12px !important;

  width: 51px !important;
  height: 51px !important;

  background: #128667 !important;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 100% !important;
  color: #ffffff !important;
}

.cancel-circular-button {
  display: flex !important;
  width: 51px !important;
  height: 51px !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 12px !important;
  box-sizing: border-box !important;
  border-radius: 100% !important;
  background: #ffffff !important;
  border: 2px solid #d0343a !important;
  color: #d0343a !important;
  box-shadow: none !important;
}

.cancel-circular-button:focus {
  display: flex !important;
  width: 51px !important;
  height: 51px !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 12px !important;
  color: #ffffff !important;
  background: #a02a2e !important;
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 50% !important;
}

.dot-button.MuiBadge-badge.MuiBadge-dot {
  height: 14px;
  width: 14px;
  left: 22px;
  top: -11px;
  border-radius: 100%;

  background: #d0343a !important;

  border: 3px solid #ffffff;
}

.dot-button.MuiBadge-badge.MuiBadge-dot:focus {
  height: 14px;
  width: 14px;
  left: 22px;
  top: -11px;
  border-radius: 100%;

  background: #a02a2e !important;

  border: 3px solid #ffffff;
}

.header {
  width: auto !important;
  height: 10px;
  left: 1736px;
  top: 363px;
  background: linear-gradient(270deg, #6f2bba 0%, #d0343a 100%);
  border-radius: 8px 8px 0 0;
}

.grid-row-container {
  margin-bottom: 36px;
}

.split-button {
  width: auto !important;
  height: 51px !important;
}

.button-with-icon-split {
  text-transform: none !important;
  color: #d0343a !important;
  background: none !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 150% !important;
  padding: 0 32px 0 32px;
}

.dropdown-split-button {
  text-transform: none !important;
  color: #d0343a !important;
  background: none !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 150% !important;
}

.dashboard-card-background {
  width: auto;
  height: auto;
  background: linear-gradient(270deg, #6f2bba 0%, #d0343a 100%);
}

.cardTitle {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #1e1e1e;
}

.card-style-global {
  width: auto !important;
  height: auto !important;
  background: #ffffff !important;

  box-sizing: border-box;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px;
  margin-bottom: 75px;
}

.sub-title {
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  font-size: 20px;
  color: #878D8D;
  font-family: "Montserrat", sans-serif !important;
}

.titleBox {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
}

.card-title-box.MuiBox-root {
  width: initial;
}

.card-title-box-reverse {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 48px;
}

.title-with-badge {
  display: flex;
  align-items: center;
}

.MuiInputBase-root {
  height: 51px;
}

.MuiTextField-root {
  align-items: center;
  height: 51px;
  background-color: #ffffff;
}

.MuiOutlinedInput-input {
  box-sizing: inherit;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.2px;
  padding: 12px 16px;
  color: #1e1e1e;
}

.label-style {
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 150%;
  letter-spacing: 0.2px;
  text-transform: capitalize;

  color: #1e1e1e;
}

.helper-text {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;

  letter-spacing: 0.2px;
}

.welcome-style {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: grey;
}

.welcome-title {
  width: 352px;
  height: 54px;
  left: 544px;
  top: 389px;

  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 150%;

  text-align: center;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  margin-bottom: 32px;


  color: #1e1e1e;
}

.welcome-logo {
  width: 352px;
  height: 54px;
  left: 544px;
  top: 389px;

  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 150%;

  text-align: center;
  letter-spacing: 0.2px;
  text-transform: capitalize;


  color: #1e1e1e;
}

.welcome-subtitle {
  width: 352px;
  height: 36px;
  left: 544px;
  top: 523px;

  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;

  text-align: center;
  letter-spacing: 0.2px;
  text-transform: capitalize;

  color: #1e1e1e;
}

.welcome-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13.5px 48px;

  width: 352px;
  height: 51px;

  background: #d0343a;
  border-radius: 4px;

  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;

  text-align: center;
  letter-spacing: 0.2px;
  text-transform: capitalize;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 8px;
}

.badge-style-global {
  margin-left: 36px;
  align-items: center;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.002em;
}

.badge-style-global .MuiBadge-badge {
  font-size: 16px;
  width: 30px;
  height: 30px;
  border-radius: 100% !important;
}

.tab-style-global {
  margin-bottom: 48px;
}

.tabBadge .MuiBadge-badge {
  font-size: 16px;
  width: 30px;
  height: 30px;
  border-radius: 100% !important;
}

.card-container-global {
  margin: 64px 64px 64px;
}

.tab-badge {
  margin: 25px 16px 0 0;

  font-size: 16px !important;
}

.tab-badge .MuiBadge-badge {
  font-size: 16px;
  width: 30px;
  height: 30px;
  border-radius: 100% !important;
}

.tabBadge {
  margin: 25px 16px 0 0;

  font-size: 16px !important;
}

.tabBadge .MuiBadge-badge {
  font-size: 16px;
  width: 30px;
  height: 30px;
  border-radius: 100% !important;
}

.tabBadge .MuiBadge-colorInfo {
  background-color: #878d8d;
}

.tabBadge .MuiBadge-colorError {
  background-color: #d0343a;
}

.sub-info {
  font-family: "Open sans", sans-serif !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 150% !important;
  letter-spacing: 0.2px !important;
  color: #1e1e1e !important;
}

.approve-avatar {
  margin: 0 0 px 0 0 !important;
  background: #f8981d;
}

.avatar-style-2 {
  color: #fff;
  background-color: #128667;
  margin-right: 16px;
}

.pagination-item-style.Mui-selected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  position: static;
  width: 48px;
  height: 48px;
  left: 0;
  top: 0;
  background: #1e1e1e;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 16px;
  color: #ffffff;
}

.pagination-item-style.MuiPaginationItem-ellipsis {
  color: #878d8d;
}

.pagination-item-style {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;

  position: static;
  width: 48px;
  height: 48px;
  top: 0;

  background: #ffffff;
  border-radius: 8px;

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0 16px;
}

.pagination-item-style:hover {
  background: #ffffff;

  border: 2px solid #1e1e1e;
  box-sizing: border-box;
  border-radius: 8px;
}

.arrow-style {
  font-size: 25px;
  color: #d0343a;
}

.current-page {
  align-items: center;
  padding: 12px 16px;
  position: static;
  width: 48px;
  height: 48px;
  left: 0;
  top: 0;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0 8px;
}

.body-cell {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;

  letter-spacing: 0.2px;

  color: #1e1e1e;
}

.table-container-global {
  margin-bottom: 32px;
}

.table-header-style {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  background-color: #f7f7f8;
  height: 72px;
  letter-spacing: 0.2px;

  color: #1e1e1e;
  border: none !important;
}

.row-error {
  background: #fce8e9 !important;
}

.row-error:hover {
  background-color: #d4979a !important;
}

.row-warning {
  background: #f7d6b7 !important;
}

.row-warning:hover {
  background-color: #e2a973 !important;
}

.title-cell {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: #1e1e1e;
}

.datagrid-global.MuiDataGrid-columnsContainer {
  background-color: #f7f7f8;
  position: relative;
  border: none;
}

.datagrid-global {
  min-height: 900px;
  width: auto;
  font-family: "Open Sans", sans-serif;
  border: none;
  padding-left: 28px;
  padding-right: 28px;
}

.datagrid-global .MuiDataGrid-virtualScrollerContent {
  position: relative;
  overflow: visible;
  height: 100% !important;
}

.datagrid-global .MuiDataGrid-row {
  margin-top: 15px !important;
}

.datagrid-global.MuiDataGrid-root .MuiDataGrid-iconSeparator {
  visibility: hidden;
}

.MuiDataGrid-header {
  background-color: grey;
}

.MuiDataGrid-footerContainer {
  justify-content: center !important;
}

.text-area-style {
  width: 912px;
  height: 1000px;
}

.footer-box {
  margin: 56px 0 26px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.footer-box.more-btns {
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
}

.footer-style {
  width: 100%;
  height: 150px !important;
  background: #ffffff !important;
  left: 0;
  bottom: 0;
}

.header-footer {
  width: 100%;
  height: 10px;
  background: linear-gradient(270deg, #6f2bba 0%, #d0343a 100%);
}

.summary-table {
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}

.summary-sideValues {
  font-size: 18px;
  font-weight: 100;
  font-family: "Open Sans", sans-serif;
  margin-top: 1em;
  margin-left: 1em;
}

.summary-sideValues3 {
  font-size: 18px;
  font-weight: 100;
  font-family: "Open Sans", sans-serif;
  margin-top: 1em;
  color: #9c9c9c;
}

.summary-value {
  font-size: 25px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  margin-top: 1em;
}

.summary-sideValue {
  font-size: 25px;
  font-weight: 100;
  font-family: "Open Sans", sans-serif;
  margin-top: 1em;
}

.summary-sideValues {
  font-size: 18px;
  font-weight: 100;
  font-family: "Open Sans", sans-serif;
  margin-top: 1em;
  margin-left: 1em;
}

.summary-sideValues1 {
  font-size: 18px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  margin-top: 1em;
}

.summary-side {
  margin: 23px 20px -3px 294px;
}

.summary-side1 {
  margin: -101px 20px 10px 227px;
}

.summary-chevron {
  margin: 23px 20px -3px 30px;
}

.summary-chevron1 {
  margin: 23px 20px -3px 30px;
}

.summary-table {
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}

.summary-check {
  position: absolute;
  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.33%;

  background-color: #128667;
}

.snackbar-style-global {
  font-family: "Open sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.2px;

  color: #ffffff;
  box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.3);
  border-radius: 8px;

  height: 84px;

  align-items: center;

  box-sizing: border-box;
  padding: 0 27px;
}

.success-snackbar {
  background-color: #128667 !important;
}

.warning-snackbar {
  background-color: #d68000 !important;
}

.error-snackbar {
  background-color: #ba0512 !important;
}

.default-snackbar {
  background-color: #006cea !important;
}

/*Alert Colors*/
.success {
  color: #128667 !important;
}

.success span {
  background-color: #128667 !important;
}

.error {
  color: #ba0512 !important;
}

.error span {
  background-color: #ba0512;
}

.info {
  color: #006cea;
}

.expired {
  color: #e4750e;
}

.expired span {
  background-color: #e4750e;
}

.rejected {
  color: #d68000;
}

.rejected span {
  background-color: #d68000;
}

/*Can be used as draft or missing alert*/
.draft {
  color: #878d8d;
}

.draft span {
  background-color: #878d8d;
}

.pending {
  color: #a35b00;
}

.pending span {
  background-color: #a35b00;
}

.alert-global.MuiPaper-root {
  border: 1px solid #d8dfe5;
  box-sizing: border-box;
  box-shadow: 2px 2px 20px rgb(0 0 0 / 15%);
}

.alert-global {
  width: auto;
  height: 137px;
  left: 11409px;
  top: 517px;
  color: #1e1e1e;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 16px;
}

.alert-global .MuiAlert-icon {
  color: #1e1e1e;
  padding-right: 32px;
}

.alert-global .MuiAlertTitle-root {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;

  letter-spacing: 0.2px;
}

.alert-default {
  background-color: #e6f0fd !important;
  border: 1px solid #006cea !important;
}

.alert-warning {
  background-color: #f7d6b7 !important;
  border: 1px solid #e4750e !important;
}

.alert-error {
  background-color: #fce8e9 !important;
  border: 1px solid #ba0512 !important;
}

.alert-success {
  background-color: #e6f3ed !important;
  border: 1px solid #128667 !important;
}

.breadcrumbs-current {
  font-family: "Open sans", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: #d0343a;
}

.breadcrumbs-previous {
  font-family: "Open sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: #585858;
}

.popup-title {
  padding: 32px 48px 24px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: #1e1e1e;
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
}

.popup-dialog-title {
  padding: 0;
}

.popup-content-text {
  padding: 40px 48px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.002em;
  color: #1e1e1e;
  height: auto;
}

.popup-container .MuiDialog-paper {
  border: 0;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 4px 4px 30px rgb(0 0 0 / 50%);
  border-radius: 8px;
}

.popup-container .MuiDialogActions-root {
  justify-content: space-between;
  padding: 32px 48px;
  box-shadow: 0 2px 15px rgb(0 0 0 / 15%);
}

.popup-button-box {
  padding: 32px 48px !important;
  display: flex;
}

.popup-grid-container {
  padding-left: 48px;
  padding-right: 48px;
}

.tooltip-container {
  padding: 12px 20px;
}

.tooltip-title {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.2px;


  color: #ffffff;


  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 10px;
}

.tooltip-p {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.2px;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0 10px;
  filter: drop-shadow(2px 2px 6px #000000);
}

.dark-tags-global.MuiChip-root {
  height: 36px;
  border: 0;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  letter-spacing: 0.002em;
  background: none;
  color: white;

  padding: 6px 16px 6px 16px;

  border-radius: 4px;
}

.dark-tags-global.MuiChip-root>.MuiChip-deleteIcon {
  color: inherit;
  margin-left: 16px;
  margin-right: 6px;
}

.dark-red-tags.MuiChip-root {
  background-color: #d0343a;
}

.dark-grey-tags.MuiChip-root {
  background-color: #585858;
}

.dark-green-tags.MuiChip-root {
  background-color: #128667;
}

.dark-blue-tags.MuiChip-root {
  background-color: #006cea;
}

.dark-purple-tags.MuiChip-root {
  background-color: #6431cf;
}

.dark-orange-tags.MuiChip-root {
  background-color: #a35b00;
}

.light-tags-global.MuiChip-root {
  height: 36px;
  border: 0;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  letter-spacing: 0.002em;
  background: none;

  padding: 6px 16px 6px 16px;

  border-radius: 4px;
}

.light-tags-global.MuiChip-root>.MuiChip-deleteIcon {
  color: inherit;
  margin-left: 16px;
  margin-right: 6px;
}

.light-red-tags.MuiChip-root {
  color: #a02a2e;
  background-color: #fce8e9;
}

.light-grey-tags.MuiChip-root {
  color: #585858;
  background-color: #dddddd;
}

.light-green-tags.MuiChip-root {
  color: #128667;
  background-color: #e6f3ed;
}

.light-blue-tags.MuiChip-root {
  color: #006cea;
  background-color: #e6f0fd;
}

.light-purple-tags.MuiChip-root {
  color: #6431cf;
  background-color: #efeafa;
}

.light-orange-tags.MuiChip-root {
  color: #a35b00;
  background-color: #ffeed3;
}

.round-tags.MuiChip-root {
  border-radius: 20px;
}

.cross-tags.MuiChip-root {
  padding: 6px 8px 6px 16px;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  background: #f6f5f5 !important;
}

.dropdown-global .MuiAutocomplete-option:hover {
  background: #e6f0fd;
  border-radius: 4px;
}

.checkbox-global.Mui-checked {
  color: #d0343a;
}

.checkbox-global.MuiCheckbox-indeterminate {
  color: #d0343a;
}

.radio-global.MuiRadio-root.Mui-checked {
  color: #d0343a;
}

.radio-global.MuiRadio-root.Mui-checked.Mui-disabled {
  color: #c2c2c2;
}

.thumb-style {
  color: #c2c2c2;
}

.h1-global {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 150%;

  letter-spacing: 0.2px;

  color: #1e1e1e;
}

.h2-global {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 28px !important;
  line-height: 150%;

  letter-spacing: 0.2px;

  color: #1e1e1e;
}

.h3-global {
  font-family: Open Sans, sans-serif;
  font-weight: bold;
  font-size: 24px !important;
  line-height: 150%;

  letter-spacing: 0.2px;

  color: #1e1e1e;
}

.h4-global {
  font-family: Open Sans, sans-serif;
  font-weight: normal;
  font-size: 20px !important;
  line-height: 150%;

  letter-spacing: 0.2px;

  color: #1e1e1e;
}

.body-text {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;

  letter-spacing: 0.2px;

  color: #1e1e1e;
}

.menu-link {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;

  letter-spacing: 0.2px;

  color: #1e1e1e;
}

.bold {
  font-weight: bold;
}

.change-container {
  padding: 24px 0;
  background: #e6f0fd50;
  border-radius: 4px;
}

.change-row {
  margin-right: 32px;
  position: relative;
  opacity: 1;
}

.grid-row-icon {
  display: flex;
  align-items: center;
}

.grey-text {
  color: #878d8d !important;
}

.top-menu-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-shadow: 2;
}

.top-menu-global {
  background-color: #ffffff;
  color: #000000;
  position: absolute;
}

.top-menu-start {
  min-width: 100;
  flex-grow: 1;
  letter-spacing: 2px;
  font-weight: bold;
}

.menu-button-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-menu-container {
  background-color: #3b3b45 !important;
  color: #ffffff;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: 2;
}

.sub-menu-container {
  background-color: #f6f5f5;
  color: #585858;
}

.menu-item-text {
  padding-left: 32px;
}

.sub-menu-text {
  padding-left: 32px;
}

.menu-item-global.Mui-selected {
  background-color: #f6f5f5;
  color: #1e1e1e;
  box-shadow: -3px 3px 4px 0 #000000;
}

.menu-item-global {
  width: 232px;
  height: 56px;
}

.sub-menu-global.Mui-selected {
  color: #d0343a;
}

.rectangle {
  width: 5px;
  height: 32px;
  left: 0;
  top: 144px;

  background: #d0343a;
  border-radius: 0 2px 2px 0;
}

.sub-menu-global.MuiButtonBase-root.MuiListItem-root.Mui-selected {
  background-color: #f6f5f5;
}

.menu-item-global.Mui-selected:hover {
  background-color: #f6f5f5;
}

.padding-left {
  padding-left: 24px !important;
}

.formio-component-datagrid {
  overflow-x: inherit !important;
}
