.bannerCustom {
  background-color: rgba(234,242,255,1);
  padding: 10px;
  height: auto;
  white-space: pre-wrap;

  .bannerCustomMessage {
    color: rgb(55,65,81);
    max-width: 900px;
    white-space: pre-wrap;
  }
}