.clickContactText{
    color: #0065bd;
    text-decoration: none;
    font-weight: bold;
    margin-left: 5;
  }
  
.clickContactText:hover {
text-decoration: underline;
color: #0065bd
}

.disablebutton:disabled{
  color:white;
  opacity: 0.5;
}

.journey-card {
  background-color: #ffffff;
  border-radius: 8px !important;
  box-shadow: 0 4px 8px #00000029;
}

@media only screen and (max-width: 465px) {
  .resp465 {width: 100%; height: 100%;}
}

/* @media only screen and (max-width: 300px) {
  .resp465 {width: 100%; height: 100%;}
} */