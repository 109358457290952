@import url("./APIAsset/customcss/reset.min.css");

@font-face {
  font-family: "Univers-65Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./APIAsset/fontsStyle/messeunivers-bold.otf") format("opentype");
}

@font-face {
  font-family: "Univers-55Roman";
  font-style: normal;
  font-weight: 400;
  src: url("./APIAsset/fontsStyle/univers-55-roman.otf") format("opentype");
}


@font-face {
  font-family: "Helvetica-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./APIAsset/fontsStyle/helveticabd___.ttf") format("opentype");
}


@font-face {
  font-family: "Optima-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("./APIAsset/fontsStyle/optima-b.TTF") format("opentype");
}

@font-face {
  font-family: "HelveticaNeue-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("./APIAsset/fontsStyle/helveticaneue.ttf") format("opentype");
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("./APIAsset/googleFonts/S6uyw4BMUTPHjxAwXjeu.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("./APIAsset/googleFonts/S6uyw4BMUTPHjx4wXg.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("./APIAsset/googleFonts/S6u9w4BMUTPHh6UVSwaPGR_p.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("./APIAsset/googleFonts/S6u9w4BMUTPHh6UVSwiPGQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmhduz8A.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwkxduz8A.woff2") format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmxduz8A.woff2") format('woff2');
  unicode-range: U+1F00-1FFF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlBduz8A.woff2") format('woff2');
  unicode-range: U+0370-03FF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmBduz8A.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmRduz8A.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url("./APIAsset/googleFonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdu.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --x565a5c: #565a5c;
  --mine-shaft: #333333;
  --hit-gray: #a6adb4;
  --white: #ffffff;
  --black: #000000;
  --science-blue: #0065bd;
  --gallery: #eaecf0;
  --shark-22: #262626;
  --eerie-black: #1b1c1d99;
  --rose: #ff4d4d;
  --lochmara: #0088ce;
  --storm-dust: #656565;
  --black-2: #000000a6;
  --log-cabin: #212121;
  --shark2: #212529;
  --link-water: #cce7f5;
  --pizazz: #ff8a00;
  --black-haze2: #f6f8f9;
  --sonic-silver: #7676764d;
  --concrete: #f3f3f366;
  --gossamer: #009b74;
  --cosmos: #ffdbdb;
  --jade: #00af3f;
  --golden-tainoi: #ffc96c;
  --sazerac: #fff4e1;
  --yellow-orange: #ffb639;
  --piggy-pink: #fee1e6;
  --wild-watermelon: #fa6980;
  --black-haze-22: #f7f7f7;
  --gallery-2: #f0f0f0;
  --dove-gray: #707070;
  --font-size-m: 16px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-xxs: 11px;
  --font-size-l: 18px;
  --font-size-xl: 22px;
  --font-family-univers-55roman: "Univers-55Roman", Helvetica;
  --font-family-universlt_std-65bold: "UniversLT Std-65Bold", Helvetica;
  --font-family-lato: "Lato", Helvetica;
  --font-family-helvetica-bold: "Helvetica-Bold", Helvetica;
  --font-family-univers-65bold: "Univers-65Bold", Helvetica;
  --font-family-univers: "Univers", Helvetica;
  --font-family-univers_lt_std: "Univers LT Std", Helvetica;
  --font-family-universlt_std-55roman: "UniversLT Std-55Roman", Helvetica;
  --font-family-optima-bold: "Optima-Bold", Helvetica;
  --font-family-helveticaneue: "HelveticaNeue", Helvetica;
}

* {
  box-sizing: border-box;
}

.ui-elements {
  background-color: var(--white);
  height: 7027px;
  position: relative;
  width: 1440px;
}

.hidden,
.hidden * {
  pointer-events: none;
  visibility: hidden;
}

.step-1 {
  align-items: flex-start;
  display: flex;
  height: 44px;
  left: 707px;
  min-width: 222px;
  position: absolute;
  top: 3677px;
}

.overlap-group22 {
  height: 44px;
  margin-top: 0;
  position: relative;
  width: 42px;
}

.base-1 {
  height: 40px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 40px;
}

.univers-65-bold-hit-gray-18px {
  color: var(--hit-gray);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.number {
  left: 15px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.univers-65-bold-science-blue-18px {
  color: var(--science-blue);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.flex-col-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: -0.5px 0 0 5px;
  min-height: 42px;
  width: 175px;
}

.univers-65-bold-mine-shaft-18px {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.what-is-grants-and-incentive-portal {
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: -9px;
  min-height: 19px;
  white-space: nowrap;
}

.group-16162-1 {
  align-items: flex-end;
  display: flex;
  height: 13px;
  margin: 10px 0 0 0;
  width: 104px;
}

.x50-completed {
  height: 15px;
  letter-spacing: 0;
  line-height: 26px;
  white-space: nowrap;
  width: 104px;
}

.univers-55roman-normal-scarpa-flow-14px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.group-16223 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 602px;
  min-height: 150px;
  position: absolute;
  top: 387px;
  width: 100px;
}

.lato-bold-shark-14px {
  color: var(--shark-22);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.cool-grey {
  letter-spacing: 0;
  line-height: 14px;
  min-height: 19px;
  white-space: nowrap;
}

.eaecf0 {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.lato-normal-storm-dust-12px {
  color: var(--storm-dust);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.rectangle-2508 {
  background-color: var(--gallery);
  height: 90px;
  width: 100px;
}

.helvetica-bold-hit-gray-20px {
  color: var(--hit-gray);
  font-family: var(--font-family-helvetica-bold);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}

.active {
  left: 387px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 3638px;
  white-space: nowrap;
}

.price-3 {
  left: 77px;
  letter-spacing: 0;
  line-height: 11px;
  position: absolute;
  top: 1399px;
  white-space: nowrap;
}

.lato-medium-eerie-black-11px {
  color: var(--eerie-black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 500;
}

.form-dropdown-default-1 {
  display: flex;
  flex-direction: column;
  height: 199px;
  justify-content: space-between;
  left: 482px;
  position: absolute;
  top: 2897px;
  width: 372px;
}

.form-input-text-default-4 {
  align-items: flex-end;
  display: flex;
  flex: 1;
  margin-right: 0.5px;
  max-height: 174px;
}

.input-base-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  min-width: 100%;
}

.overlap-group-9 {
  height: 174px;
  position: relative;
  width: 370px;
}

.input-base-4 {
  display: flex;
  height: 174px;
  left: 0;
  position: absolute;
  top: 0;
  width: 370px;
}

.input-base-5 {
  background-color: var(--white);
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  flex: 1;
  margin: -1px;
  width: 372px;
}

.tabler-icon-chevron-right-1 {
  height: 15px;
  left: 346px;
  position: absolute;
  top: 12px;
  width: 15px;
}

.label-4 {
  left: 16px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 112px;
  white-space: nowrap;
}

.univers-55roman-normal-shark-16px {
  color: var(--shark2);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.label-3 {
  left: 16px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 80px;
  white-space: nowrap;
}

.univers-65-bold-science-blue-16px {
  color: var(--science-blue);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.label-2 {
  letter-spacing: 0;
  line-height: 16px;
  min-height: 17px;
  min-width: 11px;
  white-space: nowrap;
}

.label-5 {
  left: 16px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 144px;
  white-space: nowrap;
}

.label-1 {
  left: 16px;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: 16px;
  white-space: nowrap;
}

.group-label-2 {
  height: 17px;
  letter-spacing: 0;
  line-height: 24px;
  margin: -4px 0 0 0.5px;
  white-space: nowrap;
}

.univers-55roman-normal-scarpa-flow-16px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.group-16221 {
  display: flex;
  height: 150px;
  left: 434px;
  position: absolute;
  top: 388px;
  width: 100px;
}

.group-875 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  width: 100px;
}

.rectangle-2506 {
  background-color: var(--hit-gray);
  height: 90px;
  width: 100px;
}

.a6-adb4 {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.color-3 {
  letter-spacing: 0;
  line-height: 14px;
  min-height: 19px;
  white-space: nowrap;
}

.final {
  left: 482px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 2573px;
  white-space: nowrap;
}

.h4-univers-bold {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-xl);
  font-weight: 700;
  left: 126px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 1392px;
  white-space: nowrap;
}

.btncta {
  display: flex;
  height: 24px;
  justify-content: space-between;
  left: 905px;
  position: absolute;
  top: 1878px;
  width: 116px;
}

.read-more {
  align-self: flex-end;
  color: var(--lochmara);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-m);
  font-weight: 700;
  height: 17px;
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: -20.6px;
  white-space: nowrap;
  width: 84px;
}

.icontabler {
  height: 24px;
  width: 24px;
}

.error {
  left: 482px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 2387px;
  white-space: nowrap;
}

.group-16229 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 664px;
  min-height: 150px;
  position: absolute;
  top: 163px;
  width: 100px;
}

.x009-b74 {
  left: 11px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  top: 30px;
  white-space: nowrap;
}

.teal-green {
  left: 3px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
}

.rectangle-2513 {
  background-color: var(--gossamer);
  height: 90px;
  width: 100px;
}

.univers-65-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.surname {
  letter-spacing: 0;
  line-height: 22px;
  min-height: 17px;
  text-align: center;
  white-space: nowrap;
  width: 62px;
}

.lato-medium-eerie-black-12px {
  color: var(--eerie-black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 500;
}

.price-5 {
  left: 76px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  top: 1442px;
  white-space: nowrap;
}

.icontabler-chevron {
  height: 30px;
  width: 30px;
}

.universltstd-65-bold-black-16px {
  color: var(--black);
  font-family: var(--font-family-universlt_std-65bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.attachment-button {
  left: 74px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 2094px;
  white-space: nowrap;
}

.h5-univers-bold {
  left: 126px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 1434px;
  white-space: nowrap;
}

.paragraph-univers-regular-1 {
  left: 713px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 1310px;
  white-space: nowrap;
}

.price-8 {
  left: 664px;
  letter-spacing: 0;
  line-height: 11px;
  position: absolute;
  top: 1354px;
  white-space: nowrap;
}

.paragraph-univers-regular {
  left: 713px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 1227px;
  white-space: nowrap;
}

.univers-55roman-normal-scarpa-flow-22px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.price-4 {
  left: 664px;
  letter-spacing: 0;
  line-height: 11px;
  position: absolute;
  top: 1234px;
  white-space: nowrap;
}

.footer {
  left: 74px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 4774px;
  white-space: nowrap;
  width: 314px;
}

.univers-65-bold-science-blue-40px {
  color: var(--science-blue);
  font-family: var(--font-family-univers-65bold);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.project-listing {
  left: 74px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 5907px;
  white-space: nowrap;
  width: 385px;
}

.form-form-group-default-2 {
  display: flex;
  flex-direction: column;
  height: 63px;
  justify-content: space-between;
  left: 892px;
  position: absolute;
  top: 2428px;
  width: 372px;
}

.form-input-text-default-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  margin: 0 0.5px 0 0.5px;
  max-height: 38px;
  min-width: calc(100% - 1px);
}

.overlap-group {
  height: 38px;
  position: relative;
  width: 370px;
}

.input-base {
  display: flex;
  height: 38px;
  left: 0;
  position: absolute;
  top: 0;
  width: 370px;
}

.input-base-2 {
  background-color: var(--black-haze2);
  border-radius: 5px;
  flex: 1;
  margin: -1px;
  width: 372px;
}

.border-1px-gallery {
  border: 1px solid var(--gallery);
}

.univers-55roman-normal-mine-shaft-16px {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.text-state {
  left: 12px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.group-label-1 {
  flex: 1;
  height: 17px;
  letter-spacing: 0;
  line-height: 24px;
  margin: -4px -1px 0 0;
  max-height: 17px;
  white-space: nowrap;
}

.universltstd-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-univers_lt_std);
  font-size: var(--font-size-m);
}

.header-univers-bold {
  left: 76px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1191px;
  white-space: nowrap;
}

.universltstd-55roman-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-universlt_std-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.price-2 {
  left: 77px;
  letter-spacing: 0;
  line-height: 11px;
  position: absolute;
  top: 1353px;
  white-space: nowrap;
}

.h3-univers-bold {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-65bold);
  font-size: 28px;
  font-weight: 700;
  left: 126px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 1350px;
  white-space: nowrap;
}

.group-16224 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 190px;
  min-height: 150px;
  position: absolute;
  top: 390px;
  width: 100px;
}

.rectangle-2 {
  background-color: var(--x565a5c);
  height: 90px;
  width: 100px;
}

.x565-a5-c-1 {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.dark-grey-1 {
  letter-spacing: 0;
  line-height: 14px;
  min-height: 19px;
  white-space: nowrap;
}

.website-menu {
  left: 73px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 4225px;
  white-space: nowrap;
}


.flex-row-4 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  height: 46px;
  margin-top: 25px;
  min-width: 1168px;
}

.terms-of-use {
  letter-spacing: 0;
  line-height: 26px;
  min-height: 17px;
  min-width: 102px;
  margin: 0 0 16px 78px;
  white-space: nowrap;
}

.univers-55roman-normal-white-16px {
  color: var(--white);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}


.contact-us {
  letter-spacing: 0;
  line-height: 26px;
  min-height: 17px;
  min-width: 154px;
  margin-bottom: 16px;
  white-space: nowrap;
}

.report-vulnerability {
  letter-spacing: 0;
  line-height: 26px;
  min-height: 17px;
  min-width: 154px;
  margin-bottom: 16px;
  white-space: nowrap;
}

.privacy-policy {
  letter-spacing: 0;
  line-height: 26px;
  min-height: 17px;
  min-width: 106px;
  margin: 0 0 16px 78px;
  white-space: nowrap;
}

.copyright {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 786px) {
  .terms-of-use {
    margin-left: unset;
  }

  .privacy-policy {
    margin-left: unset;
  }
}

.x2020-maritime-and {
  height: 43px;
  letter-spacing: 0;
  line-height: 26px;
  white-space: nowrap;
  width: 376px;
}

.divider {
  height: 1px;
  margin: 43px 0 0 0;
  width: 1164px;
}

.flex-row-3 {
  align-items: flex-end;
  display: flex;
  height: 94px;
  min-width: 1163px;
  margin: 21px 1px 0 0;
}

.powered-by {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 93px;
  width: 113px;
}

.power-by {
  color: var(--black-haze-22);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-weight: 400;
  height: 17px;
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
}

.logompa-white {
  height: 60px;
  margin: 16px 0 0 1.04px;
  width: 57px;
}



.social-media {
  align-items: flex-start;
  display: flex;
  height: 11px;
  min-width: 83px;
}

.social-media-iconSize {
  height: 15px;
  margin: 0.47px 0 0 16px;
  width: 15px;
}

.twitter-iconSize {
  width: 17px;
}

.youtube-iconSize {
  height: 13px;
  width: 20px;
}

.facebook-iconSize {
  width: 10px;
}

.faqs {
  letter-spacing: 0;
  line-height: 26px;
  margin: 0 0 1.31px 686px;
  min-height: 17px;
  min-width: 45px;
  white-space: nowrap;
}

.feedback {
  letter-spacing: 0;
  line-height: 26px;
  margin: 0 0 1.31px 25px;
  min-height: 17px;
  min-width: 72px;
  white-space: nowrap;
}

.usp {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  min-height: 125px;
}

.description {
  color: var(--black-haze-22);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-l);
  font-weight: 400;
  height: 62px;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 38px;
  width: 550px;
}

.one-maritime-portal {
  color: var(--white);
  font-family: var(--font-family-univers-65bold);
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 38px;
  margin: -6px 0 -10px 0;
  min-height: 28px;
  white-space: nowrap;
}

.step-3 {
  align-items: center;
  display: flex;
  height: 40px;
  left: 73px;
  min-width: 222px;
  position: absolute;
  top: 3679px;
}

.what-is-grants-and-incentive-portal-1 {
  letter-spacing: 0;
  line-height: 28px;
  margin: 0 0 10px 7px;
  min-height: 19px;
  min-width: 175px;
  white-space: nowrap;
}

.overlap-group24 {
  align-items: flex-start;
  background-color: var(--gallery);
  border-radius: 20px;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  min-width: 40px;
  padding: 6px 13px;
}

.number-5 {
  letter-spacing: 0;
  line-height: 28px;
  min-height: 18px;
  white-space: nowrap;
}

.group-16225 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 74px;
  min-height: 150px;
  position: absolute;
  top: 390px;
  width: 100px;
}

.phone {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.color-2 {
  letter-spacing: 0;
  line-height: 14px;
  min-height: 19px;
  white-space: nowrap;
}

.rectangle-24 {
  background-color: var(--mine-shaft);
  height: 90px;
  width: 100px;
}

.default {
  left: 72px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 2387px;
  white-space: nowrap;
}

.large {
  letter-spacing: 0;
  line-height: 26px;
  min-height: 17px;
  white-space: nowrap;
}

.overlap-group1-1 {
  height: 24px;
  position: relative;
  width: 1020px;
}

.command {
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
  width: 1020px;
}

.icontabler-new-section {
  height: 24px;
  left: 440px;
  position: absolute;
  top: 0;
  width: 24px;
}

.group-16219 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 197px;
  min-height: 150px;
  position: absolute;
  top: 162px;
  width: 100px;
}

.x565-a5-c {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.dark-grey {
  letter-spacing: 0;
  line-height: 14px;
  min-height: 19px;
  white-space: nowrap;
}


.univers-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-univers);
  font-size: var(--font-size-s);
}

.successful-add-new-one {
  height: 15px;
  letter-spacing: 0;
  line-height: 24px;
  margin: -6.1px 0 0 24px;
  white-space: nowrap;
  width: 169px;
}

.univers-55roman-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.univers-65-bold-white-14px {
  color: var(--white);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.close {
  height: 8px;
  margin-right: 24px;
  width: 8px;
}

.default-1 {
  left: 72px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 2858px;
  white-space: nowrap;
}

.step {
  align-items: flex-start;
  display: flex;
  height: 40px;
  left: 393px;
  min-width: 222px;
  position: absolute;
  top: 3679px;
}

.flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: -2.5px 0 0 7px;
  min-height: 42px;
  width: 175px;
}

.group-16162 {
  align-items: flex-end;
  display: flex;
  height: 13px;
  margin: 10px 0 0 0;
  width: 96px;
}

.x0-completed {
  height: 15px;
  letter-spacing: 0;
  line-height: 26px;
  white-space: nowrap;
  width: 96px;
}

.number-3 {
  left: 15px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 6px;
  white-space: nowrap;
}

.number-4 {
  left: 15px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.heading-with-subheader {
  left: 72px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1498px;
  white-space: nowrap;
}

.checked-done {
  left: 1028px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 3638px;
  white-space: nowrap;
}

.progress-active {
  left: 701px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 3638px;
  white-space: nowrap;
}

.flex-row-2 {
  align-items: flex-start;
  display: flex;
  margin: 24px 0 0 16px;
  min-width: 1096px;
}

.showing {
  display: flex;
  height: 32px;
  justify-content: space-between;
  width: 306px;
}

.entries {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: -50px 50px -50px 0;
  min-height: calc(100% + 100px);
  padding: 8px 0;
  transform: rotate(90deg);
  width: 32px;
}

.tabler-icon-chevron-down {
  height: 15px;
  margin-right: 1px;
  transform: rotate(-90deg);
  width: 15px;
}

.group-16240 {
  align-items: flex-start;
  display: flex;
  margin-top: 27px;
  min-width: 132px;
  transform: rotate(-90deg);
}

.entries-1 {
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 3.5px;
  min-height: 17px;
  min-width: 54px;
  white-space: nowrap;
}

.number-6 {
  letter-spacing: 0;
  line-height: 26px;
  min-height: 17px;
  white-space: nowrap;
}

.showing-1-10-of-35 {
  height: 17px;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -4px;
  white-space: nowrap;
  width: 146px;
}

.pagination {
  display: flex;
  height: 32px;
  margin-left: 358px;
  width: 432px;
}

.group-6 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  min-width: 432px;
}

.overlap-group3 {
  align-items: center;
  background-color: var(--white);
  border-radius: 16px;
  display: flex;
  height: 32px;
  min-width: 32px;
  padding: 0 10px;
}

.group-copy-22 {
  display: flex;
  height: 12px;
  width: 12px;
}

.down {
  flex: 1;
  margin: 1px 3.4px 0.9px 2.6px;
  width: 6px;
}

.overlap-group-7 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 16px;
  display: flex;
  height: 32px;
  justify-content: flex-end;
  margin-left: 8px;
  min-width: 32px;
  padding: 6px 11px;
}

.univers-55roman-normal-black-14px {
  color: var(--black-2);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.number-2 {
  letter-spacing: 0;
  line-height: 24px;
  min-height: 15px;
  text-align: center;
  white-space: nowrap;
  width: 9px;
}

.overlap-group9 {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 16px;
  display: flex;
  height: 32px;
  justify-content: flex-end;
  margin-left: 8px;
  min-width: 32px;
  padding: 10.9px 12.6px;
}

.down-1 {
  height: 10px;
  width: 6px;
}

.pagination-button {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 16px;
  display: flex;
  height: 32px;
  justify-content: flex-end;
  margin-left: 8px;
  min-width: 32px;
  padding: 5px 11px;
}

.overlap-group8 {
  align-items: flex-start;
  background-color: var(--science-blue);
  border-radius: 16px;
  display: flex;
  height: 32px;
  justify-content: flex-end;
  margin-left: 8px;
  min-width: 32px;
  padding: 6px 11px;
}

.table-list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 24px 0 0 0.81px;
  min-height: 688px;
  width: 1159px;
}

.overlap-group13 {
  height: 641px;
  margin-left: 0.15px;
  position: relative;
  width: 1159px;
}

.rejected-2 {
  align-items: center;
  display: flex;
  height: 35px;
  left: 51px;
  min-width: 1062px;
  position: absolute;
  top: 463px;
}

.x-container-2 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: -5.5px;
  min-height: 36px;
  width: 155px;
}

.univers-55roman-normal-hit-gray-12px {
  color: var(--hit-gray);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.submitted-on-21-oct-2021-3 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 6px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.asi-20200210-003 {
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: -9px;
  min-height: 17px;
  white-space: nowrap;
}

.overlap-group-5 {
  height: 20px;
  position: relative;
  width: 16px;
}

.line-18 {
  height: 8px;
  left: 7px;
  position: absolute;
  top: 8px;
  width: 2px;
}

.path-10451 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

.path-10452 {
  height: 5px;
  left: 4px;
  position: absolute;
  top: 11px;
  width: 9px;
}

.path-10450 {
  height: 7px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 7px;
}

.tag-5 {
  align-items: flex-start;
  background-color: var(--cosmos);
  border-radius: 12px;
  display: flex;
  height: 24px;
  margin: 0 0 1px 111px;
  min-width: 90px;
  padding: 0.5px 10.5px;
}

.border-1px-rose {
  border: 1px solid var(--rose);
}

.univers-65-bold-rose-12px {
  color: var(--rose);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.withdrawal {
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  white-space: nowrap;
}

.overlap-group-6 {
  border-radius: 9px;
  height: 20px;
  position: relative;
  width: 20px;
}

.path-10458 {
  height: 7px;
  left: 6px;
  position: absolute;
  top: 7px;
  width: 7px;
}

.ellipse-403 {
  border-radius: 9px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.border-2px-rose {
  border: 2px solid var(--rose);
}

.component-163-10 {
  display: flex;
  height: 24px;
  margin: 0 0 5px 95px;
  width: 24px;
}

.path-10715 {
  flex: 1;
  margin: 4px 2px 3px 2px;
  width: 20px;
}

.ellipse-40 {
  border-radius: 1px;
  height: 4px;
  margin-left: 3px;
  width: 4px;
}

.border-2px-mine-shaft {
  border: 2px solid var(--mine-shaft);
}

.ellipse-399 {
  border-radius: 1px;
  height: 4px;
  width: 4px;
}

.flex-col-5 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin: -5.5px 0 0 61px;
  min-height: 36px;
  width: 142px;
}

.main-application {
  letter-spacing: 0;
  line-height: 26px;
  margin: 0 0 -9px 0.88px;
  min-height: 17px;
  white-space: nowrap;
}

.msi-ais-4 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 6px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.rectangle-6242 {
  background: linear-gradient(180deg, #fa6980 0%, #ff4d4d 100%);
  border-radius: 5px;
  height: 8px;
  margin: 0 0 1px 106px;
  width: 99px;
}

.change-request {
  align-items: center;
  display: flex;
  height: 33px;
  left: 50px;
  min-width: 1063px;
  position: absolute;
  top: 335px;
}

.component-163 {
  display: flex;
  height: 24px;
  margin: 2px 0 0 75px;
  width: 24px;
}

.flex-col {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin: -5.5px 0 0 60px;
  min-height: 34px;
  width: 204px;
}

.text_label {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.local-subsidiary-sister {
  letter-spacing: 0;
  line-height: 26px;
  margin: 0 0 -9px 1.91px;
  min-height: 17px;
  white-space: nowrap;
}

.percent {
  letter-spacing: 0;
  line-height: 26px;
  margin: 0 0 7px 3px;
  min-height: 17px;
  min-width: 43px;
  white-space: nowrap;
}

.tag {
  align-items: flex-start;
  background-color: var(--link-water);
  border-radius: 12px;
  display: flex;
  height: 24px;
  justify-content: flex-end;
  margin: 1px 0 0 51px;
  min-width: 92px;
  padding: 0.5px 12.6px;
}

.border-1px-lochmara {
  border: 1px solid var(--lochmara);
}

.univers-65-bold-lochmara-12px {
  color: var(--lochmara);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.processing {
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  min-width: 64px;
  text-align: center;
  white-space: nowrap;
}

.x-container {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: -5.5px;
  min-height: 34px;
  width: 155px;
}

.motified-on-21-oct-2021 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.asi-20200210-005 {
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: -9px;
  min-height: 17px;
  white-space: nowrap;
}

.rectangle-6239 {
  background: linear-gradient(180deg, #0088ce 0%, #0065bd 100%);
  border-radius: 5px;
  height: 8px;
  margin: 1px 0 0 104px;
  width: 100px;
}

.draft-1 {
  align-items: flex-start;
  display: flex;
  height: 33px;
  left: 51px;
  min-width: 1062px;
  position: absolute;
  top: 144px;
}

.main-application-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: -5.5px 0 0 43px;
  min-height: 34px;
  width: 157px;
}

.msi-ml-main-application {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.main-application-2 {
  letter-spacing: 0;
  line-height: 26px;
  margin: 0 0 -9px 1.02px;
  min-height: 17px;
  white-space: nowrap;
}

.component-163-3 {
  display: flex;
  height: 24px;
  margin: 1px 0 0 81px;
  width: 24px;
}

.percent-2 {
  align-self: center;
  letter-spacing: 0;
  line-height: 26px;
  margin: 0 0 7px 5px;
  min-height: 17px;
  min-width: 34px;
  white-space: nowrap;
}

.overlap-group11 {
  align-self: center;
  background-color: var(--gallery);
  border-radius: 5px;
  height: 8px;
  margin: 1px 0 0 53px;
  width: 100px;
}

.border-1px-golden-tainoi {
  border: 1px solid var(--golden-tainoi);
}

.tag-7 {
  align-items: flex-start;
  align-self: center;
  background-color: var(--sazerac);
  border-radius: 12px;
  display: flex;
  height: 24px;
  margin: 1px 0 0 99px;
  min-width: 40px;
  padding: 0.5px 4.4px;
}

.draft-2 {
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  white-space: nowrap;
}

.univers-65-bold-yellow-orange-12px {
  color: var(--yellow-orange);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.tag-8 {
  align-items: flex-start;
  align-self: center;
  background-color: var(--piggy-pink);
  border-radius: 12px;
  display: flex;
  height: 24px;
  margin: 1px 0 0 6px;
  min-width: 95px;
  padding: 0.5px 7.5px;
}

.due-in-7-days {
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  white-space: nowrap;
}

.univers-65-bold-wild-watermelon-12px {
  color: var(--wild-watermelon);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.x-container-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: -5.5px;
  min-height: 34px;
  width: 172px;
}

.modified-on-21-oct-2021 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.asi-20200210-1930-p-1 {
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: -9px;
  min-height: 17px;
  white-space: nowrap;
}

.rejected {
  align-items: center;
  display: flex;
  height: 35px;
  left: 51px;
  min-width: 1062px;
  position: absolute;
  top: 595px;
}

.x-container-1 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: -5.5px;
  min-height: 36px;
  width: 154px;
}

.asi-20200210-001 {
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: -9px;
  min-height: 17px;
  white-space: nowrap;
}

.submitted-on-21-oct-2021-2 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 6px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.flex-col-4 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin: -5.5px 0 0 62px;
  min-height: 36px;
  width: 142px;
}

.msi-ais-2 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 6px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.tag-3 {
  align-items: flex-start;
  background-color: var(--cosmos);
  border-radius: 12px;
  display: flex;
  height: 24px;
  margin: 0 0 1px 112px;
  min-width: 74px;
  padding: 0.5px 11.1px;
}

.rejected-1 {
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  white-space: nowrap;
}

.component-163-9 {
  display: flex;
  height: 24px;
  margin: 0 0 5px 96px;
  width: 24px;
}

.path-10725 {
  height: 8px;
  margin: 0 0 1px 121px;
  width: 99px;
}

.change-request-1 {
  align-items: center;
  display: flex;
  height: 33px;
  left: 50px;
  min-width: 1063px;
  position: absolute;
  top: 271px;
}

.submitted-on-21-oct-2021 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.asi-20200210-006 {
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: -9px;
  min-height: 17px;
  white-space: nowrap;
}


.tag-1 {
  align-items: flex-start;
  background-color: var(--link-water);
  border-radius: 12px;
  display: flex;
  height: 24px;
  justify-content: flex-end;
  margin: 0 0 1px 51px;
  min-width: 92px;
  padding: 0.5px 12.6px;
}

.msi-ais {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.awaiting-approval {
  align-items: center;
  display: flex;
  height: 33px;
  left: 50px;
  min-width: 1063px;
  position: absolute;
  top: 207px;
}

.tag-6 {
  align-items: flex-start;
  background-color: var(--link-water);
  border-radius: 12px;
  display: flex;
  height: 24px;
  justify-content: flex-end;
  margin: 1px 0 0 113px;
  min-width: 85px;
  padding: 0.5px 8.6px;
}

.rectangle-6263 {
  background: linear-gradient(180deg, #0088ce 0%, #0065bd 100%, #0088ce 100%);
  border-radius: 5px;
  height: 8px;
  margin: 1px 0 0 111px;
  width: 100px;
}

.x-container-3 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: -5.5px;
  min-height: 34px;
  width: 172px;
}

.submitted-on-21-oct-2021-4 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.asi-20200210-1930-p {
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: -9px;
  min-height: 17px;
  white-space: nowrap;
}

.component-163-4 {
  display: flex;
  height: 24px;
  margin: 1px 0 0 76px;
  width: 24px;
}

.flex-col-6 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin: -5.5px 0 0 43px;
  min-height: 34px;
  width: 141px;
}

.msi-asi {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.main-application-1 {
  letter-spacing: 0;
  line-height: 26px;
  margin: 0 0 -9px 0.09px;
  min-height: 17px;
  white-space: nowrap;
}

.percent-1 {
  letter-spacing: 0;
  line-height: 26px;
  margin: 0 0 7px 2px;
  min-height: 17px;
  min-width: 43px;
  white-space: nowrap;
}

.approved {
  align-items: center;
  display: flex;
  height: 33px;
  left: 51px;
  min-width: 1062px;
  position: absolute;
  top: 528px;
}

.tag-4 {
  align-items: flex-start;
  background-color: #ccebe3;
  border: 1px solid var(--gossamer);
  border-radius: 12px;
  display: flex;
  height: 24px;
  margin: 1px 0 0 112px;
  min-width: 76px;
  padding: 0.5px 7px;
}

.approved-1 {
  color: var(--jade);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-xs);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  white-space: nowrap;
}

.component-163-8 {
  display: flex;
  height: 24px;
  margin: 0 0 3px 95px;
  width: 24px;
}

.flex-col-1 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin: -5.5px 0 0 61px;
  min-height: 34px;
  width: 142px;
}

.msi-ais-3 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.overlap-group-12 {
  align-items: flex-start;
  border: 2px solid var(--jade);
  border-radius: 9px;
  display: flex;
  height: 20px;
  min-width: 20px;
  padding: 4.6px 3.4px;
}

.path-10456 {
  height: 6px;
  width: 9px;
}

.path-10454 {
  height: 8px;
  margin: 1px 0 0 117px;
  width: 100px;
}

.accepted-on-21-oct-2021 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.asi-20200210-002 {
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: -9px;
  min-height: 17px;
  white-space: nowrap;
}

.resubmit {
  align-items: center;
  display: flex;
  height: 33px;
  left: 51px;
  min-width: 1062px;
  position: absolute;
  top: 399px;
}

.msi-ais-1 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.component-163-7 {
  display: flex;
  height: 24px;
  margin: 0 0 3px 75px;
  width: 24px;
}

.rectangle-6239-1 {
  background: linear-gradient(180deg, #0088ce 0%, #0065bd 100%);
  border-radius: 5px;
  height: 8px;
  margin: 1px 0 0 77px;
  width: 100px;
}

.submitted-on-21-oct-2021-1 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.asi-20200210-004 {
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: -9px;
  min-height: 17px;
  white-space: nowrap;
}

.tag-2 {
  align-items: flex-start;
  background-color: var(--link-water);
  border-radius: 12px;
  display: flex;
  height: 24px;
  margin: 3px 0 0 110px;
  min-width: 121px;
  padding: 0 12.4px;
}

.update-request-1 {
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  min-width: 94px;
  text-align: center;
  white-space: nowrap;
}

.draft-3 {
  display: flex;
  height: 33px;
  left: 51px;
  position: absolute;
  top: 80px;
  width: 1065px;
}

.group-15958 {
  align-items: center;
  display: flex;
  flex: 1;
  margin-left: 0;
  min-width: 1064.89px;
}

.percent-3 {
  letter-spacing: 0;
  line-height: 26px;
  margin: 0 0 7px 7px;
  min-height: 17px;
  min-width: 34px;
  white-space: nowrap;
}

.asi-20200210-007 {
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: -9px;
  min-height: 17px;
  white-space: nowrap;
}

.created-on-21-oct-2021 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 0;
  min-height: 13px;
  white-space: nowrap;
}

.tag-10 {
  align-items: flex-start;
  background-color: var(--piggy-pink);
  border-radius: 12px;
  display: flex;
  height: 24px;
  margin: 1px 0 0 6px;
  min-width: 95px;
  padding: 0.5px 7.5px;
}

.due-in-7-days-1 {
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  white-space: nowrap;
}

.component-163-1 {
  display: flex;
  height: 24px;
  margin: 1px 0 0 78px;
  width: 24px;
}

.tag-9 {
  align-items: flex-start;
  background-color: var(--sazerac);
  border-radius: 12px;
  display: flex;
  height: 24px;
  margin: 1px 0 0 118px;
  min-width: 40px;
  padding: 0.5px 4.4px;
}

.draft-4 {
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  white-space: nowrap;
}

.overlap-group1-4 {
  background-color: var(--gallery);
  border-radius: 5px;
  height: 8px;
  margin: 1px 0 0 53px;
  width: 100px;
}

.flex-col-7 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin: -5.5px 0 0 57px;
  min-height: 34px;
  width: 141px;
}

.msi-ais-5 {
  letter-spacing: 0;
  line-height: 24px;
  margin: 4px 0 -11px 1px;
  min-height: 13px;
  white-space: nowrap;
}

.main-application-3 {
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: -9px;
  min-height: 17px;
  white-space: nowrap;
}

.new-1 {
  align-items: flex-end;
  display: flex;
  height: 47px;
  left: 0;
  min-width: 1116px;
  position: absolute;
  top: 0;
}

.overlap-group12 {
  height: 47px;
  position: relative;
  width: 1000px;
}

.tag-11 {
  align-items: flex-start;
  background-color: #ffe8cc;
  border: 1px solid var(--pizazz);
  border-radius: 12px;
  display: flex;
  height: 24px;
  left: 522px;
  min-width: 40px;
  padding: 0.5px 5.2px;
  position: absolute;
  top: 20px;
}

.new-2 {
  -webkit-text-stroke: 1px transparent;
  color: var(--pizazz);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-xs);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  white-space: nowrap;
}

.award-name {
  display: flex;
  flex-direction: column;
  height: 33px;
  justify-content: space-between;
  left: 259px;
  position: absolute;
  top: 14px;
  width: 234px;
}

.main-application-extension {
  -webkit-text-stroke: 1px transparent;
  flex: 1;
  height: 17px;
  letter-spacing: 0;
  line-height: 26px;
  margin: -5.5px -2px 0 0;
  max-height: 17px;
  white-space: nowrap;
}

.msi-ais-6 {
  -webkit-text-stroke: 1px transparent;
  height: 13px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 0 4.5px 2px;
  white-space: nowrap;
  width: 52px;
}

.progress {
  align-items: flex-end;
  display: flex;
  height: 44px;
  left: 0;
  min-width: 1000px;
  position: absolute;
  top: 0;
}

.percent-4 {
  -webkit-text-stroke: 1px transparent;
  align-self: center;
  letter-spacing: 0;
  line-height: 26px;
  margin: 12px 0 0 8px;
  min-height: 17px;
  min-width: 25px;
  white-space: nowrap;
}

.rectangle-6258 {
  align-self: flex-start;
  height: 10px;
  width: 10px;
}

.path-10531 {
  height: 8px;
  margin: 0 0 8px 705px;
  width: 100px;
}

.component-163-2 {
  display: flex;
  height: 24px;
  margin-left: 84px;
  width: 24px;
}

.case-id {
  display: flex;
  flex-direction: column;
  height: 31px;
  justify-content: space-between;
  left: 50px;
  position: absolute;
  top: 16px;
  width: 168px;
}

.created-on-21-oct-2021-1 {
  -webkit-text-stroke: 1px transparent;
  height: 13px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 4.5px;
  white-space: nowrap;
  width: 133px;
}

.asi-20200210-1930-p-2 {
  -webkit-text-stroke: 1px transparent;
  flex: 1;
  height: 17px;
  letter-spacing: 0;
  line-height: 26px;
  margin: -5.5px -2px 0 0;
  max-height: 17px;
  white-space: nowrap;
}

.item {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 641px;
  position: absolute;
  top: 0;
  width: 1159px;
}

.overlap-group-4 {
  height: 64px;
  margin-left: 0;
  position: relative;
  width: 1161px;
}

.border-2px-gallery {
  border: 2px solid var(--gallery);
}

.checkbox {
  border-radius: 3px;
  height: 16px;
  left: 16px;
  position: absolute;
  top: 24px;
  width: 16px;
}

.path-10530 {
  height: 1px;
  margin-left: 0.5px;
  width: 1160.85px;
}

.menu {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 47px;
  width: 1159px;
}

.path-10706 {
  height: 1px;
  margin-top: 15px;
  width: 1159px;
}

.path-10707 {
  height: 1px;
  width: 1159px;
}

.flex-row-1 {
  align-items: flex-start;
  display: flex;
  height: 20px;
  margin: 12px 12.12px 0 0;
  min-width: 1111px;
}

.actions {
  letter-spacing: 0;
  line-height: 22px;
  margin-left: 69px;
  min-height: 13px;
  white-space: nowrap;
}

.univers-55roman-normal-scarpa-flow-12px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.sort {
  align-self: center;
  display: flex;
  flex-direction: column;
  height: 9px;
  justify-content: space-between;
  margin: 0.24px 0 0 3px;
  width: 7px;
}

.icon-material-arrow-drop-up {
  flex: 1;
  height: 3.52px;
  margin: 0;
  max-height: 3.52px;
}

.icon-material-arrow-drop-down {
  flex: 1;
  height: 3.52px;
  margin: 0;
  max-height: 3.52px;
}

.overall-progress {
  letter-spacing: 0;
  line-height: 22px;
  margin-left: 134px;
  min-height: 13px;
  min-width: 125px;
  white-space: nowrap;
}

.award-name-type {
  letter-spacing: 0;
  line-height: 22px;
  margin-left: 146px;
  min-height: 13px;
  min-width: 134px;
  white-space: nowrap;
}

.checkbox-2 {
  align-self: flex-end;
  border-radius: 3px;
  height: 16px;
  width: 16px;
}

.document-s {
  letter-spacing: 0;
  line-height: 22px;
  margin-left: 85px;
  min-height: 13px;
  min-width: 80px;
  white-space: nowrap;
}

.sort-1 {
  align-self: center;
  display: flex;
  flex-direction: column;
  height: 9px;
  justify-content: space-between;
  margin: 0.24px 0 0 4px;
  width: 7px;
}

.surname-2 {
  letter-spacing: 0;
  line-height: 22px;
  margin-left: 16px;
  min-height: 13px;
  min-width: 52px;
  white-space: nowrap;
}

.status-1 {
  letter-spacing: 0;
  line-height: 22px;
  margin-left: 113px;
  min-height: 13px;
  min-width: 52px;
  white-space: nowrap;
}

.what-is-grants-and-i {
  align-items: flex-end;
  display: flex;
  height: 20px;
  margin: 11px 0 0 30px;
  min-width: 462px;
}

.what-is-grants-and-incentive-portal-2 {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: 28px;
  min-height: 19px;
  min-width: 109px;
  white-space: nowrap;
}

.what-is-grants-and-incentive-portal-3 {
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 44px;
  min-height: 18px;
  min-width: 124px;
  opacity: 0.5;
  white-space: nowrap;
}

.univers-55roman-normal-mine-shaft-18px {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.what-is-grants-and-incentive-portal-4 {
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 58px;
  min-height: 18px;
  min-width: 127px;
  opacity: 0.5;
  white-space: nowrap;
}

.flex-row {
  align-items: center;
  align-self: center;
  display: flex;
  margin-top: 30px;
  min-width: 1101px;
}

.status {
  align-items: center;
  display: flex;
  min-width: 705px;
}

.group-16195 {
  align-items: flex-start;
  background-color: var(--hit-gray);
  border-radius: 9.5px;
  display: flex;
  height: 19px;
  justify-content: flex-end;
  margin-right: 14px;
  min-width: 19px;
  padding: 0 3px;
}

.univers-65-bold-scarpa-flow-16px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.number-1 {
  letter-spacing: 0;
  line-height: 22px;
  margin-top: -0.5px;
  min-height: 17px;
  white-space: nowrap;
}

.processing-1 {
  height: 17px;
  letter-spacing: 0;
  line-height: 22px;
  margin: -6.6px 0 0 16px;
  text-align: center;
  white-space: nowrap;
  width: 87px;
}

.overlap-group-3 {
  height: 20px;
  position: relative;
  width: 65px;
}

.group-16194 {
  align-items: flex-start;
  background-color: var(--hit-gray);
  border-radius: 9.5px;
  display: flex;
  height: 19px;
  justify-content: flex-end;
  left: 46px;
  min-width: 19px;
  padding: 0 3px;
  position: absolute;
  top: 1px;
}

.draft {
  left: 0;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 62px;
}

.group-16193 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 9.5px;
  display: flex;
  height: 19px;
  justify-content: flex-end;
  left: 46px;
  min-width: 19px;
  padding: 0 3px;
  position: absolute;
  top: 1px;
}

.new {
  left: 0;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 62px;
}

.group-16197 {
  align-items: flex-start;
  background-color: var(--rose);
  border-radius: 9.5px;
  display: flex;
  height: 19px;
  justify-content: flex-end;
  margin-right: 13px;
  min-width: 19px;
  padding: 0 3px;
}

.update-request {
  height: 17px;
  letter-spacing: 0;
  line-height: 22px;
  margin: -6.6px 0 0 16px;
  text-align: center;
  white-space: nowrap;
  width: 127px;
}

.group-16198 {
  align-items: flex-start;
  background-color: var(--hit-gray);
  border-radius: 9.5px;
  display: flex;
  height: 19px;
  justify-content: flex-end;
  margin-right: 13px;
  min-width: 19px;
  padding: 0 3px;
}

.completed {
  height: 17px;
  letter-spacing: 0;
  line-height: 22px;
  margin: -6.6px 0 0 16px;
  text-align: center;
  white-space: nowrap;
  width: 90px;
}

.f-ilter-option {
  align-items: flex-start;
  display: flex;
  margin: 1px 0 0 290px;
  min-width: 106px;
}

.icontabler-1 {
  height: 24px;
  margin-left: 17px;
  width: 24px;
}

.path-container {
  height: 4px;
  margin-top: 35px;
  position: relative;
  width: 1159px;
}

.path-10435 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 1159px;
}

.path-10526 {
  height: 4px;
  left: 48px;
  position: absolute;
  top: 0;
  width: 61px;
}

.line-border {
  left: 602px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 343px;
  white-space: nowrap;
}

.group-16220 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 314px;
  min-height: 150px;
  position: absolute;
  top: 388px;
  width: 100px;
}

.mid-blue {
  left: 11px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  top: 10px;
  white-space: nowrap;
}

.lato-normal-log-cabin-12px {
  color: var(--log-cabin);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.x0065-bd-1 {
  left: 11px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  top: 30px;
  white-space: nowrap;
}

.path {
  height: 90px;
  width: 100px;
}

.step-2 {
  align-items: center;
  display: flex;
  height: 40px;
  left: 1028px;
  min-width: 222px;
  position: absolute;
  top: 3679px;
}

.group-16216 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 195px;
  min-height: 150px;
  position: absolute;
  top: 605px;
  width: 100px;
}

.ff8-a00 {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.lato-bold-mine-shaft-14px {
  color: var(--mine-shaft);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 700;
}

.color {
  letter-spacing: 0;
  line-height: 14px;
  margin-left: 1px;
  min-height: 19px;
  white-space: nowrap;
}

.paragraph-univers-regular-4 {
  left: 1172px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1216px;
  white-space: nowrap;
}

.group-16217 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 326px;
  min-height: 150px;
  position: absolute;
  top: 605px;
  width: 100px;
}

.ffc96-c {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.error-alert {
  left: 79px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 3758px;
  white-space: nowrap;
}

.univers {
  align-items: center;
  background-color: var(--concrete);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  left: 277px;
  min-height: 170px;
  padding: 27px 25.5px;
  position: absolute;
  top: 980px;
  width: 172px;
}

.border-1px-sonic-silver {
  border: 1px solid var(--sonic-silver);
}

.aa-1 {
  color: var(--eerie-black);
  font-family: var(--font-family-universlt_std-55roman);
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 60px;
  margin-left: 1px;
  min-height: 62px;
  min-width: 79px;
  white-space: nowrap;
}

.line-165 {
  align-self: flex-start;
  height: 1px;
  margin-top: 10px;
  width: 110px;
}

.universltstd-55roman-normal-eerie-black-12px {
  color: var(--eerie-black);
  font-family: var(--font-family-universlt_std-55roman);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.univers-1 {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 21px;
  min-height: 14px;
  min-width: 46px;
  text-align: center;
  white-space: nowrap;
}

.disable {
  left: 892px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 2387px;
  white-space: nowrap;
}

.form-textarea-default {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 71px;
  min-height: 116px;
  position: absolute;
  top: 3268px;
  width: 372px;
}

.group-label-3 {
  letter-spacing: 0;
  line-height: 24px;
  margin: -4px 0 -7px 0;
  min-height: 17px;
  white-space: nowrap;
}

.overlap-group19 {
  height: 92px;
  margin-top: 12px;
  position: relative;
  width: 370px;
}

.line {
  height: 3px;
  width: 3px;
}

.input-base-7 {
  display: flex;
  height: 92px;
  left: 0;
  position: absolute;
  top: 0;
  width: 370px;
}

.input-base-1 {
  background-color: var(--white);
  border-radius: 5px;
  flex: 1;
  margin: -1px;
  width: 372px;
}

.error-2 {
  background-color: var(--pizazz);
  border-radius: 24px;
  display: flex;
  height: 48px;
  justify-content: space-between;
  left: 430px;
  position: absolute;
  top: 5476px;
  width: 328px;
}

.warning-password-expirses-in-1-day {
  height: 15px;
  letter-spacing: 0;
  line-height: 24px;
  margin: -6.1px 0 0 24px;
  white-space: nowrap;
  width: 249px;
}

.form-form-group-default-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 72px;
  min-height: 116px;
  position: absolute;
  top: 2614px;
  width: 372px;
}

.form-input-text-default {
  align-items: flex-start;
  display: flex;
  margin: 12px 0 0 1px;
  min-width: 370px;
}

.input-base-6 {
  background-color: var(--white);
  border: 2px solid var(--science-blue);
  border-radius: 5px;
  flex: 1;
  margin: -2px;
  width: 374px;
}

.helper-text-1 {
  height: 50px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 4px;
  width: 372px;
}

.group-label {
  letter-spacing: 0;
  line-height: 24px;
  margin: -4px 0 -7px 0.5px;
  min-height: 17px;
  white-space: nowrap;
}

.price-9 {
  left: 1123px;
  letter-spacing: 0;
  line-height: 11px;
  position: absolute;
  top: 1220px;
  white-space: nowrap;
}

.breadcrumbs {
  display: flex;
  height: 24px;
  justify-content: space-between;
  left: 69px;
  position: absolute;
  top: 4022px;
  width: 253px;
}

.component-257-1 {
  align-self: flex-end;
  display: flex;
  height: 11px;
  margin-bottom: 7px;
  width: 205px;
}

.surname-1 {
  color: var(--hit-gray);
  flex: 1;
  font-family: var(--font-family-univers);
  font-size: var(--font-size-xs);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22px;
  margin: -5.5px -2px 3.5px 0;
  white-space: nowrap;
  width: 205px;
}

.span3 {
  font-family: var(--font-family-univers-65bold);
}

.univers-65-bold-scarpa-flow-12px {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 700;
}

.span1 {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-65bold);
}

.group-16226 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 320px;
  min-height: 150px;
  position: absolute;
  top: 162px;
  width: 100px;
}

.text-7 {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.dark-blue {
  letter-spacing: 0;
  line-height: 14px;
  min-height: 19px;
  white-space: nowrap;
}

.rectangle-2510 {
  background-color: #002395;
  height: 90px;
  width: 100px;
}

.paragraph-univers-regular-5 {
  left: 663px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1189px;
  white-space: nowrap;
}

.colours {
  left: 76px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 42px;
  white-space: nowrap;
  width: 442px;
}

.previous-next-button {
  left: 412px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1964px;
  white-space: nowrap;
}

.title {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-65bold);
  font-size: 46px;
  font-weight: 700;
  left: 125px;
  letter-spacing: 0;
  line-height: 56px;
  position: absolute;
  top: 1230px;
  white-space: nowrap;
}

.paragraph-univers-regular-2 {
  color: var(--x565a5c);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-l);
  font-weight: 400;
  left: 713px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 1270px;
  white-space: nowrap;
}

.tabs {
  left: 74px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 5676px;
  white-space: nowrap;
  width: 385px;
}

.price-7 {
  left: 664px;
  letter-spacing: 0;
  line-height: 11px;
  position: absolute;
  top: 1316px;
  white-space: nowrap;
}

.alert {
  left: 74px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 5400px;
  white-space: nowrap;
  width: 161px;
}

.back-to-top-button {
  left: 74px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1964px;
  white-space: nowrap;
}

.get-the-grants-and-i {
  left: 72px;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  top: 1533px;
  white-space: nowrap;
}

.univers-65-bold-mine-shaft-38px {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-65bold);
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
}

.dropdown-1 {
  left: 72px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 2811px;
  white-space: nowrap;
}

.default-2 {
  left: 76px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 3646px;
  white-space: nowrap;
}

.get-the-grants-and-i-1 {
  height: 104px;
  left: 71px;
  letter-spacing: 0;
  line-height: 32px;
  position: absolute;
  top: 1592px;
  width: 693px;
}

.form-form-group-default-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 72px;
  min-height: 116px;
  position: absolute;
  top: 2428px;
  width: 372px;
}

.univers-55roman-normal-hit-gray-16px {
  color: var(--hit-gray);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.form-form-group-default-1 {
  display: flex;
  flex-direction: column;
  height: 52px;
  justify-content: space-between;
  left: 482px;
  position: absolute;
  top: 2614px;
  width: 372px;
}

.form-input-text-default-1 {
  align-items: flex-end;
  display: flex;
  flex: 1;
  height: 15px;
  margin: 0 269px 0 3px;
  max-height: 15px;
}

.text-state-1 {
  height: 17px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
  width: 101px;
}

.univers-65-bold-mine-shaft-16px {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.applicant-menu-flow-2 {
  align-items: flex-end;
  display: flex;
  height: 130px;
  justify-content: center;
  left: 0;
  min-width: 1440px;
  position: absolute;
  top: 4531px;
}

.overlap-group27 {
  height: 142px;
  margin-bottom: -12px;
  position: relative;
  width: 1458px;
}

.logoomp-colour {
  align-items: flex-end;
  display: flex;
  height: 56px;
  justify-content: flex-end;
  left: 148px;
  min-width: 141px;
  position: absolute;
  top: 49px;
}

.path-container-1 {
  height: 55px;
  margin-bottom: 0;
  position: relative;
  width: 61px;
}

.path-10674 {
  height: 47px;
  left: 0;
  position: absolute;
  top: 0;
  width: 61px;
}

.path-10675 {
  height: 9px;
  left: 8px;
  position: absolute;
  top: 46px;
  width: 46px;
}

.overlap-group-15 {
  height: 57px;
  margin: 0 0 -0.25px 11px;
  position: relative;
  width: 70px;
}

.one {
  left: 0;
  letter-spacing: 0;
  line-height: 19px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.optima-bold-mine-shaft-16px {
  color: var(--mine-shaft);
  font-family: var(--font-family-optima-bold);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.place-2 {
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 37px;
  white-space: nowrap;
}

.maritime {
  left: 0;
  letter-spacing: 0;
  line-height: 19px;
  position: absolute;
  top: 19px;
  white-space: nowrap;
}

.overlap-group1-5 {
  height: 38px;
  left: 1154px;
  position: absolute;
  top: 57px;
  width: 36px;
}

.number-8 {
  color: var(--white);
  font-family: var(--font-family-univers-65bold);
  font-size: var(--font-size-xs);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22px;
  min-height: 13px;
  min-width: 8px;
  text-align: center;
  white-space: nowrap;
}

.icontabler-inbox {
  height: 24px;
  left: 0;
  position: absolute;
  top: 14px;
  width: 24px;
}

.masthead {
  background-color: var(--gallery-2);
  display: flex;
  /* height: 30px; */
  left: 9px;
  position: absolute;
  top: 0;
  width: 1440px;
}

.agencyheader1 {
  background-color: var(--gallery-2);
  display: flex;
  width: 100%;
  /* height: 30px; */
  align-items: center;
  margin: auto;
}

.agencyheader2 {
  display: flex;
  width: 100%;
  align-items: center;
  max-width: 1200px;
  margin: auto;
}

.univers-55roman-normal-mine-shaft-14px {
  color: var(--mine-shaft);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.a-singapore-governme {
  height: 15px;
  letter-spacing: 0;
  line-height: 22px;
  margin: -5px 0 0 22.1px;
  white-space: nowrap;
  width: 354px;
}

.logomerlion {
  margin: 7px 0 6.7px 138px;
  width: 14px;
}

.user-name-login {
  display: flex;
  flex-direction: column;
  height: 36px;
  justify-content: space-between;
  left: 1206px;
  position: absolute;
  top: 63px;
  width: 97px;
}

.user-name {
  flex: 1;
  height: 17px;
  letter-spacing: 0;
  line-height: 24px;
  margin: -4.5px 8px 0 0;
  max-height: 17px;
  white-space: nowrap;
}

.company-name {
  flex: 1;
  height: 13px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 -2px 4.5px 0;
  max-height: 13px;
  white-space: nowrap;
}

.hover {
  background-color: var(--lochmara);
  height: 5px;
  margin: 36px 0 0 30px;
  width: 50px;
}

.flex-row-5 {
  align-items: flex-start;
  display: flex;
  margin-top: 36px;
  min-width: 366px;
}

.dashboard {
  display: flex;
  height: 15px;
  width: 118px;
}

.dashboards {
  flex: 1;
  letter-spacing: 0;
  line-height: 26px;
  margin: -5.5px -2px 3.5px 0;
  white-space: nowrap;
  width: 118px;
}

.incentives {
  display: flex;
  height: 15px;
  margin-left: 34px;
  width: 98px;
}

.incentives-1 {
  flex: 1;
  letter-spacing: 0;
  line-height: 26px;
  margin: -5.5px -2px 3.5px;
  white-space: nowrap;
  width: 98px;
}

.grants {
  display: flex;
  height: 15px;
  margin-left: 46px;
  width: 70px;
}

.place-1 {
  flex: 1;
  letter-spacing: 0;
  line-height: 26px;
  margin: -5.5px -2px 3.5px 0;
  white-space: nowrap;
  width: 70px;
}

.group-16232 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 894px;
  min-height: 150px;
  position: absolute;
  top: 394px;
  width: 100px;
}

.color-1 {
  letter-spacing: 0;
  line-height: 14px;
  min-height: 19px;
  white-space: nowrap;
}

.ffffff {
  color: #767676;
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.dropdown-remove {
  left: 482px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 2858px;
  white-space: nowrap;
}

.price {
  left: 77px;
  letter-spacing: 0;
  line-height: 11px;
  position: absolute;
  top: 1247px;
  white-space: nowrap;
}

.radio-checkbox {
  left: 481px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 3216px;
  white-space: nowrap;
}

.large-button {
  height: 17px;
  letter-spacing: 0;
  line-height: 26px;
  margin: -6.3px 0 0 21px;
  white-space: nowrap;
  width: 100px;
}

.icontabler-arrow-narrow-right {
  margin: 12.1px 16.3px 11.9px 0;
  width: 24px;
}

.caption-univers-regular {
  left: 1122px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1181px;
  white-space: nowrap;
}

.text-colours {
  left: 76px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 343px;
  white-space: nowrap;
}

.place {
  left: 72px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 2335px;
  white-space: nowrap;
}

.cta-button {
  left: 905px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1829px;
  white-space: nowrap;
}

.steps {
  left: 72px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 3512px;
  white-space: nowrap;
  width: 146px;
}

.applicant-menu {
  left: 73px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 4476px;
  white-space: nowrap;
}

.toggle {
  left: 72px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 2573px;
  white-space: nowrap;
}

.corporate-colours {
  left: 76px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 117px;
  white-space: nowrap;
}

.error-4 {
  background-color: var(--rose);
  border-radius: 24px;
  display: flex;
  height: 48px;
  justify-content: space-between;
  left: 74px;
  position: absolute;
  top: 5476px;
  width: 328px;
}

.error-please-try-again {
  height: 15px;
  letter-spacing: 0;
  line-height: 24px;
  margin: -6.1px 0 0 24px;
  white-space: nowrap;
  width: 153px;
}

.form-form-group-default {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 482px;
  min-height: 116px;
  position: absolute;
  top: 2428px;
  width: 372px;
}

.helper-text-2 {
  color: var(--rose);
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-xs);
  font-weight: 400;
  height: 50px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 4px;
  width: 372px;
}

.btn-back-to-top {
  align-items: flex-start;
  display: flex;
  height: 50px;
  left: 76px;
  min-width: 52px;
  position: absolute;
  top: 2003px;
}

.overlap-group28 {
  height: 50px;
  position: relative;
  width: 52px;
}

.path-10729 {
  height: 8px;
  width: 15px;
}

.container {
  height: 50px;
  left: 0;
  position: absolute;
  top: 0;
  width: 50px;
}

.explore {
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  text-align: center;
  top: 22px;
  white-space: nowrap;
  width: 52px;
}

.form {
  left: 72px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 2275px;
  white-space: nowrap;
  width: 101px;
}

.slider-btn {
  left: 905px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1964px;
  white-space: nowrap;
}

.step-4 {
  align-items: center;
  display: flex;
  height: 40px;
  left: 79px;
  min-width: 222px;
  position: absolute;
  top: 3795px;
}

.optima {
  align-items: center;
  background-color: var(--concrete);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  left: 76px;
  min-height: 170px;
  padding: 21.5px 25.5px;
  position: absolute;
  top: 980px;
  width: 172px;
}

.aa {
  color: var(--eerie-black);
  font-family: var(--font-family-optima-bold);
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 60px;
  margin-right: 6px;
  min-height: 71px;
  min-width: 72px;
  white-space: nowrap;
}

.line-1 {
  align-self: flex-start;
  height: 1px;
  margin-top: 6px;
  width: 110px;
}

.optima-1 {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 21px;
  min-height: 14px;
  min-width: 44px;
  text-align: center;
  white-space: nowrap;
}

.paragraph-univers-regular-3 {
  left: 713px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 1350px;
  white-space: nowrap;
}

.form-dropdown-default {
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: space-between;
  left: 72px;
  position: absolute;
  top: 2897px;
  width: 372px;
}

.form-input-text-default-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 55.25px;
  min-height: 55.25px;
}

.helper-text {
  letter-spacing: 0;
  line-height: 16px;
  margin: 3px 0 -3px 0;
  min-height: 13px;
  white-space: nowrap;
}

.overlap-group1-2 {
  height: 40px;
  margin-top: -1px;
  position: relative;
  width: 372px;
}

.tabler-icon-chevron-right {
  height: 15px;
  width: 15px;
}

.text-state-2 {
  left: 14px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.group-16231 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 894px;
  min-height: 150px;
  position: absolute;
  top: 163px;
  width: 100px;
}

.x69-be28 {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.light-green {
  letter-spacing: 0;
  line-height: 14px;
  min-height: 19px;
  white-space: nowrap;
}

.form-input-radio-group-default {
  display: flex;
  height: 18px;
  justify-content: space-between;
  left: 488px;
  position: absolute;
  top: 3253px;
  width: 30px;
}

.label {
  letter-spacing: 0;
  line-height: 24px;
  margin: -3px -2px 1px 0;
  white-space: nowrap;
  width: 11px;
}

.helveticaneue-normal-shark-16px {
  color: var(--shark2);
  font-family: var(--font-family-helveticaneue);
  font-size: var(--font-size-m);
  font-style: normal;
}

.radio {
  align-items: flex-start;
  background-color: var(--science-blue);
  border-radius: 8px;
  margin-top: 2px;
  min-width: 16px;
  padding: 4px;
}

.ellipse-3 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  width: 6px;
}

.button-size {
  left: 74px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1829px;
  white-space: nowrap;
}

.corporate-font {
  left: 76px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 905px;
  white-space: nowrap;
  width: 442px;
}

.group-16230 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 779px;
  min-height: 150px;
  position: absolute;
  top: 163px;
  width: 100px;
}

.x00-af3-f {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.mid-green {
  letter-spacing: 0;
  line-height: 14px;
  min-height: 19px;
  white-space: nowrap;
}

.btn-right-swipe {
  align-items: flex-start;
  border: 1px solid var(--hit-gray);
  border-radius: 25px;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  left: 474px;
  min-width: 50px;
  padding: 9px 9.1px;
  position: absolute;
  top: 2003px;
}

.breadcrumb {
  left: 74px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 3953px;
  white-space: nowrap;
  width: 485px;
}

.form-input-checkbox-group-default {
  display: flex;
  height: 18px;
  left: 558px;
  position: absolute;
  top: 3253px;
  width: 28px;
}

.form-input-checkbox-group-default-1 {
  display: flex;
  justify-content: space-between;
  width: 30px;
}

.checkbox-1 {
  align-items: flex-end;
  background-color: var(--science-blue);
  border-radius: 3px;
  display: flex;
  margin: 2.5px 0 2px 0;
  min-width: 14.17px;
  padding: 3px 3px;
}

.check {
  height: 7px;
  width: 8px;
}

.background-colours {
  left: 770px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 343px;
  white-space: nowrap;
}

.h2-univers-bold {
  left: 126px;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  top: 1290px;
  white-space: nowrap;
}

.text-area {
  left: 71px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 3216px;
  white-space: nowrap;
}

.header {
  left: 74px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 4147px;
  white-space: nowrap;
  width: 314px;
}

.information-view-my-settings {
  height: 15px;
  letter-spacing: 0;
  line-height: 24px;
  margin: -6.1px 0 0 24px;
  white-space: nowrap;
  width: 204px;
}

.group-16227 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 434px;
  min-height: 150px;
  position: absolute;
  top: 162px;
  width: 100px;
}

.x0065-bd {
  left: 11px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  top: 30px;
  white-space: nowrap;
}

.form-switches {
  align-items: flex-start;
  display: flex;
  height: 24px;
  justify-content: center;
  left: 628px;
  min-width: 39px;
  position: absolute;
  top: 3253px;
}

.overlap-group20 {
  align-items: flex-start;
  background-color: var(--science-blue);
  border-radius: 100px;
  display: flex;
  height: 26px;
  justify-content: flex-end;
  margin-top: -1px;
  min-width: 41px;
  padding: 3px 5px;
}

.control {
  background-color: var(--white);
  border-radius: 9px;
  height: 18px;
  width: 18px;
}

.price-1 {
  left: 77px;
  letter-spacing: 0;
  line-height: 11px;
  position: absolute;
  top: 1300px;
  white-space: nowrap;
}

.website-navigation {
  display: flex;
  flex-direction: column;
  height: 130px;
  left: 0;
  position: absolute;
  top: 4277px;
  width: 1440px;
}

.masthead-1 {
  background-color: var(--gallery-2);
  display: flex;
  flex: 1;
  height: 30px;
  max-height: 30px;
}

.a-singapore-governme-1 {
  height: 15px;
  letter-spacing: 0;
  line-height: 22px;
  margin: -5px 0 0 17.2px;
  white-space: nowrap;
  width: 338px;
}

.logomerlion-1 {
  margin: 7px 0 6.7px 139px;
  width: 14px;
}

.top-navi {
  align-items: center;
  background-color: var(--white);
  box-shadow: 0 3px 6px #0000000d;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  min-width: 100%;
}

.top-navi-transparent {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.137);
  min-width: 100%;
}

.top-navi-appbar .MuiAppBar-root {
  background: none;
}

.login {
  letter-spacing: 0;
  line-height: 26px;
  margin: 0.5px 0 0 8px;
  min-height: 17px;
  white-space: nowrap;
}

.main-list {
  align-items: flex-end;
  display: flex;
  margin: 1px 0 0 71px;
  min-width: 627px;
}

.fa-qs {
  display: flex;
  height: 15px;
  margin-left: 28px;
  width: 44px;
}

.faqs-1 {
  flex: 1;
  letter-spacing: 0;
  line-height: 22px;
  margin: -3.5px -2px 1.5px 0;
  white-space: nowrap;
  width: 44px;
}

.contact-us-1 {
  align-self: flex-start;
  display: flex;
  height: 15px;
  margin-left: 28px;
  width: 105px;
}

.contact-us-2 {
  flex: 1;
  letter-spacing: 0;
  line-height: 22px;
  margin: -3.5px -2px 1.5px 0;
  white-space: nowrap;
  width: 105px;
}

.about-us-1 {
  display: flex;
  height: 15px;
  margin-left: 30px;
  width: 101px;
}

.about-omp {
  flex: 1;
  letter-spacing: 0;
  line-height: 22px;
  margin: -3.5px -2px 1.5px 0;
  white-space: nowrap;
  width: 101px;
}

.btnexploreschemes {
  align-self: flex-start;
  display: flex;
  width: 175px;
}

.explore-schemes {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.about-us {
  display: flex;
  height: 15px;
  width: 157px;
}

.explore-schemes-1 {
  flex: 1;
  letter-spacing: 0;
  line-height: 22px;
  margin: -3.5px -2px 1.5px 0;
  white-space: nowrap;
  width: 157px;
}

.tabler-icon-chevron-right-2 {
  display: flex;
  transform: rotate(90deg);
  width: 15px;
}

.tabler-icon-chevron-right-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  min-width: 15px;
}

.path-container-2 {
  height: 15px;
  position: relative;
  width: 15px;
}

.path-10419 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-90deg);
  width: 15px;
}

.path-10420 {
  height: 6px;
  left: 2px;
  position: absolute;
  top: 4px;
  transform: rotate(-90deg);
  width: 10px;
}

.circulars {
  display: flex;
  height: 15px;
  margin-left: 26px;
  width: 92px;
}

.circulars-1 {
  flex: 1;
  letter-spacing: 0;
  line-height: 22px;
  margin: -3.5px -2px 1.5px 0;
  white-space: nowrap;
  width: 92px;
}

.logoomp-colour-1 {
  align-self: flex-start;
  height: 56px;
  width: 142px;
}

.progress-button {
  left: 73px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 3588px;
  white-space: nowrap;
}

.button {
  left: 72px;
  letter-spacing: 0;
  line-height: 50px;
  position: absolute;
  top: 1763px;
  white-space: nowrap;
  width: 146px;
}

.group-16222 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 770px;
  min-height: 150px;
  position: absolute;
  top: 389px;
  width: 100px;
}

.f6-f8-f9 {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.light-grey {
  letter-spacing: 0;
  line-height: 14px;
  min-height: 19px;
  white-space: nowrap;
}

.rectangle-6352 {
  background-color: var(--black-haze2);
  height: 90px;
  width: 100px;
}

.group-876 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 74px;
  min-height: 150px;
  position: absolute;
  top: 605px;
  width: 100px;
}

.ff4-d4-d {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.price-6 {
  left: 663px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  top: 1278px;
  white-space: nowrap;
}

.alert-colours {
  left: 76px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 573px;
  white-space: nowrap;
}

.tab {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 74px;
  min-height: 50px;
  position: absolute;
  top: 5749px;
  width: 1161px;
}

.flex-row-6 {
  align-items: flex-start;
  display: flex;
  height: 18px;
  margin-top: -6px;
  min-width: 371px;
}

.tab1 {
  letter-spacing: 0;
  line-height: 28px;
  min-height: 18px;
  min-width: 108px;
  white-space: nowrap;
}

.tab3 {
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 58px;
  min-height: 18px;
  opacity: 0.5;
  white-space: nowrap;
}

.tab2 {
  letter-spacing: 0;
  line-height: 28px;
  margin-left: 58px;
  min-height: 18px;
  min-width: 59px;
  opacity: 0.5;
  white-space: nowrap;
}

.overlap-group29 {
  height: 4px;
  margin-top: 34px;
  position: relative;
  width: 1161px;
}

.divider-1 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 1161px;
}

.hover-1 {
  background-color: var(--lochmara);
  height: 4px;
  left: 28px;
  position: absolute;
  top: 0;
  width: 53px;
}

.group-16228 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 549px;
  min-height: 150px;
  position: absolute;
  top: 162px;
  width: 100px;
}

.light-blue {
  letter-spacing: 0;
  line-height: 14px;
  min-height: 19px;
  white-space: nowrap;
}

.x0088-ce {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 3px;
  min-height: 17px;
  white-space: nowrap;
}

.form-dropdownand-field-default {
  display: flex;
  flex-direction: column;
  height: 80px;
  justify-content: space-between;
  left: 928px;
  position: absolute;
  top: 2897px;
  width: 372px;
}

.form-input-text-default-5 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 7px;
  max-height: 56.12px;
  min-height: 56.12px;
}

.overlap-group1-3 {
  height: 40px;
  margin-left: 0.5px;
  position: relative;
  width: 363px;
}

.txt_field {
  align-items: flex-start;
  display: flex;
  height: 38px;
  left: 0;
  min-width: 363px;
  position: absolute;
  top: 1px;
}

.overlap-group-11 {
  height: 38px;
  position: relative;
  width: 363px;
}

.text-state-3 {
  left: 104px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.input-base-8 {
  display: flex;
  height: 38px;
  left: 0;
  position: absolute;
  top: 0;
  width: 363px;
}

.input-base-9 {
  flex: 1;
  margin: -1px;
  width: 364.5px;
}

.dropdown-2 {
  display: flex;
  height: 16px;
  justify-content: space-between;
  width: 57px;
}

.price-10 {
  color: #6c757d;
  font-family: var(--font-family-univers-55roman);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin: -4px 0 3px 0;
  white-space: nowrap;
  width: 37px;
}

.icondropdown-arrowdefault {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2px;
  width: 14px;
}

.icondropdown-arrow {
  height: 6px;
  margin: 4px -1px 0 0;
  width: 12px;
}

.group-label-4 {
  flex: 1;
  height: 17px;
  letter-spacing: 0;
  line-height: 24px;
  margin: -4px -2px 0 0.5px;
  max-height: 17px;
  white-space: nowrap;
}

.slider {
  align-items: flex-start;
  display: flex;
  height: 8px;
  left: 910px;
  min-width: 36px;
  position: absolute;
  top: 2028px;
}

.ellipse-407 {
  background-color: #0065be;
  border: 1px solid #0064bd;
  border-radius: 4px;
  height: 8px;
  width: 8px;
}

.ellipse-4 {
  background-color: var(--dove-gray);
  border-radius: 4px;
  height: 8px;
  margin-left: 6px;
  opacity: 0.24;
  width: 8px;
}

.group-16218 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 74px;
  min-height: 150px;
  position: absolute;
  top: 163px;
  width: 100px;
}

.rectangle-8 {
  background: linear-gradient(89.44deg, #0088ce 0.4%, #009b74 99.54%);
  height: 90px;
  width: 100px;
}

.overlap-group4 {
  height: 64px;
  position: relative;
  width: 100px;
}

.rectangle-7 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100px;
}

.gradient {
  left: 7px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  top: 8px;
  white-space: nowrap;
}

.x0088-ce-009-b74 {
  left: 11px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  top: 32px;
  white-space: nowrap;
}

.labeled-icon-button {
  left: 416px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1829px;
  white-space: nowrap;
}

a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  color: #0065BD;
}

.notification {
  background-color: transparent;
  color: white;
  position: relative;
  display: inline-block;
}

.notification .badge {
  position: absolute;
  bottom: 10px;
  right: 5px;
  border-radius: 50%;
  background: red;
  color: white;
}


.mobilefaq {
  width: 100%;
  max-width: 100%;
}

.selectmobile {
  width: 100%;
  max-width: 100%;
}

.wraptext {
  white-space: normal !important;
  word-wrap: anywhere !important;
}

.card-content {
  margin-top: 32px;
  padding: 0 29px 14px 40px;
  border-bottom: 1px solid var(--gallery);
  height: 155px;
  word-break: break-word;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.card-main {
  width: 365px;
  height: 406px;
  border: 1px solid var(--gallery);
}

.card-head {
  padding: 17px 29px;
}

.card-btn {
  text-transform: none;
  text-align: center;
  min-height: 64px;
  width: 100%;
}