.image {
  height: 56px;
  margin-bottom: 10.07px;
  width: 142px;
}

.popup-padding {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
}

.header-inner-container {
  display: flex;
  flex: 1;
  max-width: 1200px;
}

@media (max-width: 1055px) {
  .responsive-header {
    display: none !important;
  }
}

@media (max-width: 560px) {
  .responsive-header2 {
    display: none;
  }
}

@media (min-width: 1055px) {
  .responsive-hamburger {
    display: none;
  }

  .responsive-hamburger2 {
    display: none;
  }

  .OMP-right-margin {
    margin-right: 66px;
  }
}

.fakeHeader {
  width: 100%;
  background-color: transparent;
  position: sticky;
}

.underline {
  border-bottom: 3px solid green;
  width: 50%;
  position: absolute;
  margin-left: 15%;
}

.center {
  top: 100%;
  width: 150px;
  transform: translate(0, 35%);
  border-bottom: 3px solid transparent;
  text-align: center;
  align-items: center;
  align-content: center;
}

.center2 {
  top: 100%;
  width: 150px;
  transform: translate(0, 25%);
  border-bottom: 3px solid transparent;
  text-align: center;
  align-items: center;
  align-content: center;
}

.center3 {
  top: 100%;
  transform: translate(0, 25%);
  width: 250px;
  text-align: center;
  align-items: center;
  align-content: center;
}

.center4 {
  top: 100%;
  transform: translate(0, 30%);
  width: 250px;
  text-align: center;
  align-items: center;
  align-content: center;
}

.appbarcolor { 
  padding-right: 0 !important;
  background-color: transparent;
  position: relative;
  z-index: 10;
}

.appbarcolor2 {
  background-color: white;
  position: relative;
  z-index: 10;
}

.styletabnew {
  color: white;
}

.styletabnew:hover {
  color: grey;
}

.hamburgerColor {
  color: white;
}

.hamburgerColor2 {
  color: black;
}
