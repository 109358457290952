.review-button {
  border: 2px solid var(--science-blue);
  border-radius: 8px;
  padding: 4px 8px;
  cursor: pointer;
}

.review-container {
    display: none;
    position: fixed;
    justify-content: center;
    align-items: center;
    background-color: #ffffffd9;
    /* -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px); */
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.review {
    display: flex;
    flex-direction: column;
    background-color: #ffffffd1;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    width: 435px;
    height: 535px;
    padding: 35px;
    border: 0.15em #C9C9C9 solid;
    border-radius: 22px;
  }
  .review > h2:nth-child(1) {
    margin: 0;
  }
  .review > ul:nth-child(2) {
    list-style: none;
    padding: 0;
    margin-top:15px;
    flex-grow: 1;
  }
  .review > ul:nth-child(2) > li {
    text-indent: -5px;
  }
  .review > ul:nth-child(2) > li:before {
    content: "- ";
    text-indent: -5px;
  }
  .review > button {
    background-color: white;
    border: 0.15em #C9C9C9 solid;
    cursor: pointer;
    color: #C9C9C9;
    width: 80px;
    height: 30px;
    align-self: center;
    transition: all 0.2s;
  }
  .review > button:hover {
    border-color: gray;
    color: gray;
  }