.FAQs .MuiFormControlLabel-label {
    font-family: "Univers-55Roman", sans-serif;
}

.IDPText {
    margin-left: 20px;
    text-align: justify;
}

.IDPText_NoJustify {
    margin-left: 20px;
}

.IDPTextNormal {
    margin-left: 20px;
}

.clickContactText{
    color: #0065bd !important;
    text-decoration: none;
  }

.clickContactText:hover {
    text-decoration: underline;
    color: #0065bd
}

@media screen {
    .mobilehrefview{
        max-width:100%
    }
}

.WHTTable1Img {
    max-width: 100%;
}

.WHTTable2Img {
    max-width: 100%;
}

.click-zoom input[type=checkbox] {
    display: none
  }

.click-zoom img {
    transition: transform 0.25s ease;
    cursor: zoom-in
  }

.click-zoom input[type=checkbox]:checked~img {
    transform: scale(1.7);
    cursor: zoom-out
}

.mobilefaq{
    width: fit-content;
    max-width: 350px;
    /* width: 100%; */
    /* max-width: 200px; */
}

.selectmobile{
    width: fit-content;
    max-width: 350px;
    /* width: 100% */
}

.MuiFormControl-root {
    width: 280 !important;
}
