.applicationform {
  padding: 16px 4px;
  background-color: #f6f8f9;
}

.applicationform .alert,
.applicationform .checkbox,
.applicationform .error {
  position: unset;
}

.applicationform .alert {
  width: unset;
}

.applicationform .pagination {
  margin-left: unset;
  height: unset;
  width: unset;
  display: none;
}

.applicationform .radio {
  background-color: unset;
}

.applicationform .checkbox {
  width: unset;
  height: unset;
}

.applicationform .active {
  position: unset;
  top: unset;
  left: unset;
}

.applicationform .input-group-text {
  height: 100%;
}

.applicationform input,
.applicationform textarea {
  border-radius: 5px;
}

.applicationform .input-group>input {
  border-radius: 5px 0 0 5px;
}

.applicationform .input-group-append>.input-group-text {
  border-radius: 0 5px 5px 0;
}

.applicationform .input-group-prepend+input {
  border-radius: 0 5px 5px 0;
}

.applicationform .input-group-prepend>.input-group-text {
  border-radius: 5px 0 0 5px;
}

.formio-component {
  font-family: var(--font-family-univers-55roman);
}

.formio-component button,
.formio-component .dropdown {
  border-radius: 5px;
}

.applicationform .btn:not(.formio-button-remove-row, .removeRow, .editRow) {
  border-radius: 24px;
  font-family: var(--font-family-univers-65bold);    
  font-size: 16px;
}

/* alternate way to hide the self-assessment wizard breadcumb page 1  */
.pagination li:first-child {
  display: none;
}

div[id^='error-list-'] {
  display: none;
}

.formio-component-selectboxes.formio-component-label-hidden.required .form-check-label:before {
  position: unset !important;
}

button[type='submit'] {
  /* margin-top: 12px; */
  margin-bottom: 12px;
}

.formio-component-editgrid>button[ref],
.editgrid-actions>button[ref] {
  margin-top: 12px;
  margin-bottom: 12px;
}

div .checkbox {
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
}

ul.formio-wizard-nav-container.list-inline {
  margin-top: 8px !important;
}

.pagination-carousel .alice-carousel__prev-btn,
.pagination-carousel .alice-carousel__next-btn {
  width: unset !important;
  position: relative;
  top: -22px;
  left: 88%;
  background-size: 26px;
  background-repeat: no-repeat;
}

.pagination-carousel .alice-carousel__prev-btn {
  background-image: url('../../Images/chevron-left.svg');
  background-blend-mode: color-burn;
}

.pagination-carousel .alice-carousel__next-btn {
  background-image: url('../../Images/chevron-right.svg');
}

.pagination-carousel .alice-carousel__prev-btn-item,
.pagination-carousel .alice-carousel__next-btn-item {
  border: 1px solid gray;
}

div[ref='header'] {
  background-image: linear-gradient(to right, #0065bd, #0088ce);
  height: 48px;
  border: #A6ADB480 1px solid;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

div[ref='header'] > span.mb-0 {
  color: white;
  font-size: 18px;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

div[ref='header'] > span.mb-0 > .fa-angle-down,
div[ref='header'] > span.mb-0 > .fa-angle-up {
  color: white !important;
}

div[ref='header'] > span.mb-0 > .fa-plus-square-o,
div[ref='header'] > span.mb-0 > .fa-minus-square-o {
  color: #0088ce !important;
}

.reviewerror {
  border: 1px solid red;
  padding: 24px 24px 6px;
  margin: 24px 240px;
  min-width: 400px;
  color: red;
  font-family: var(--font-family-univers-55roman);
}

.reviewerror > ul {
  list-style: disc;
}