.about-omp-sec1 {
  width: 100%;
  min-width: 780px;
}

.container-center-horizontal {
  height: auto;
  position: relative;
}

.screen-container2 {
  max-width: 1400px;
  border: 1px solid #EAECF0;
  border-radius: 12px;
  height: 100%;
}
.about-omp-Sec2overlap {
  margin-bottom: -35px;
  max-height: 350px;
  max-width: 538px;
  height: 100%;
  width: 100%;
  position: relative;
  top: -25px;
}
.about-omp-sec2 {
  background: #ffffff !important;
  width: 75%;
  margin-inline: auto;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
  margin-top: 12px;
}


@media (max-width: 768px) {
  .about-omp-sec1 {
    min-width: auto;
  }
  .about-omp-sec2 {
    margin-top: 0;
  }
  .about-omp-Sec2overlap {
    top: -20px;
  }
  .screen-container2 {
    min-width: auto;
  }
}

#aboutomppic {
  height: 355px; /* Let the image adjust its height proportionally */
  mix-blend-mode: multiply;
  opacity: 1;
  margin-top: -51px;
  margin-left: 130px;
  
}
@media (max-width: 600px) {
  #aboutomppic {
    display: none;
  }
  .textomp{
    width: 100% !important;
  }
}
.contactUsImg {
  object-fit: cover;
}
@media only screen and (min-width: 1920px) {
  .exploreschemes {width: 100%;}

  #aboutomppic {
    height: 355px; /* Let the image adjust its height proportionally */
    mix-blend-mode: multiply;
    opacity: 1;
    margin-top: -51px;
    margin-left: 330px;
    min-width: 100% !important
  }

}
.textomp{
  width: 80%;
}


