$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #373a3c;
$gray-900: #212529;
$black: #000;

// $blue: #127abf;
$blue: #0065bd;
$indigo: #6610f2;
$purple: #613d7c;
$pink: #e83e8c;
$red: #e74a3b;
$orange: #ee7f01;
$yellow: #f6c23e;
$green: #449414;
$teal: #36b9cc;
$cyan: #9954bb;
$lightblue: lighten($blue, 20%);

$primary: $blue;
$secondary: $orange;
$success: $green;
$info: $teal;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$brand-primary: #127abf;
$brand-secondary: #449414;

@import "~bootswatch/dist/cosmo/_variables.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootswatch/dist/cosmo/_bootswatch.scss";
$fa-font-path: "../fonts";
@import "~font-awesome/scss/font-awesome";

@import "node_modules/@tabler/icons/iconfont/tabler-icons.scss";

@import "~formiojs/dist/formio.full.css";

.logo {
  height: 1.9em;
}

.text-blue {
  color: $brand-primary;
}

.text-green {
  color: $brand-secondary;
}
