.button-Letter-of-Offer {
  padding: 14px 48px;
  text-transform: none;
  width: auto;
  height: 70px;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 700;
  font-size: var(--font-size-m) !important;
  line-height: 24px;
  color: var(--white);
  background-color: var(--lochmara);
  text-align: center;
  letter-spacing: 0;
}

.button-Letter-of-Offer:hover {
  background-color: var(--science-blue);
  color: var(--white);
}