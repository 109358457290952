.customOl {
  ol {
    counter-reset: list;
  }

  ol > li {
    list-style: none;
  }

  ol > li:before{
    content: "("counter(list, lower-alpha)") ";
    counter-increment: list
  }
}